<template>
 
    <p>You have the right to withdraw from this contract within 14 days without giving any reason. The 
       cancellation period is 14 days from the day on which you or a third party named by you who is not the 
       carrier took possession of the last goods. To exercise your right of withdrawal, you must tell us:</p>

    
    <p>H-2 Technik UG, Donauschwabenstr. 13, 76149 Neureut Karlsruhe, phone: 0721 6624655, email: 
        contact@h-2technik.com</p>

    
    <p>by means of a clear statement (e.g. a letter sent by post, fax or e-mail) of your decision to withdraw from 
       this contract. You can use the attached sample revocation <a href="https://h-2technik.com/online/widerrufsrecht/H-2Technk_Widerrufsbelehrung_formular(EN).pdf">
        form</a> for this, but this is not mandatory. To 
       meet the cancellation deadline, it is sufficient for you to send the communication regarding your exercise 
       of the right of cancellation before the cancellation period has expired.</p>
    
    <h2>Consequences of withdrawal</h2>
    <p>If you revoke this contract, we pay you all payments that we have received from you, including the 
       delivery costs (with the exception of the additional costs resulting from the fact that you have chosen a 
       different type of delivery than the cheapest standard delivery offered by us have), immediately and at the 
       latest within 14 days from the day on which we received the notification of your cancellation of this 
       contract. For this repayment, we use the same means of payment that you used in the original 
       transaction, unless something else was expressly agreed with you; under no circumstances you are to be
       charged fees for this repayment.</p>
     <p>
      We may refuse to refund until we have received the returned goods or until you have provided proof that 
       you have returned the goods, whichever is earlier.
     </p>

     <p>
      You must return or hand over the goods to us immediately and in any case no later than 14 days from the 
      day on which you inform us of the cancellation of this contract. The deadline is met if you send back the 
       goods before the period of 14 days has expired. We bear the costs of returning the goods.
     </p>

     <p>You are not obliged to return the item if the entrepreneur involved in the related contract has offered to 
      collect the items. In principle, you bear the direct costs of returning the goods. This does not apply if the 
      entrepreneur involved in the linked contract has agreed to bear these costs or has failed to inform the 
      consumer about the obligation to bear the direct costs of the return. In the case of off-premises contracts 
      where the goods have been delivered to the consumer's home at the time the contract is concluded, the 
      entrepreneur is obliged to collect the goods at his own expense if the goods are of such a nature that 
      they cannot be returned by post .
    </p>

     <p>If you cannot return the item provided on the basis of the associated contract, or only in part, or only in a 
      deteriorated condition, you must pay compensation in this respect. However, this is only possible if the 
      loss in value is due to handling of the goods that was not necessary to check the nature, properties and 
      functionality of the goods.
    </p>
    

</template>

<script>
  export default {
    name: 'RightWidthdraw',

  }
</script>
<style>
</style>