<template>
    <div class="space-align-container">
      <div class="space-align-block">
        <a-card title="Imprint" :bordered="true" style="width: 100%;">
                <p style="text-align: left;"><strong>Company Name:</strong> H-2 Technik UG (HRB 724558 AG Mannheim)</p>

                <p style="text-align: left;"><strong>Registered Address:</strong> Donauschwabenstr. 13, Karlsruhe, 76149 Germany</p>

                <p style="text-align: left;"><strong>VAT Identification Number (USt-ID-Nr.):</strong> DE305625924</p>

                <p style="text-align: left;"><strong>Contact Information:</strong></p>
                <ul style="text-align: left;">
                    <li><strong>Phone:</strong> +49-721-6624655</li>
                    <li><strong>Email:</strong> <a href="mailto:buchhaltung@h-2technik.com">buchhaltung@h-2technik.com</a></li>
                </ul>

                <p style="text-align: left;"><strong>Commercial Register:</strong></p>
                <ul style="text-align: left;">
                    <li><strong>Commercial Register Number (HRB):</strong> 724558</li>
                    <li><strong>Registry Court (AG):</strong> Mannheim</li>
                </ul>

                <p style="text-align: left;"><strong>Legal Form:</strong> Unternehmergesellschaft (haftungsbeschränkt) (UG)</p>

                <p  style="text-align: left;"><strong>Managing Director:</strong> Mr. He Wei </p>
        </a-card>
      </div>

    </div>
  </template>
  
  
  <script>
    export default {
    name: 'ImprintInfo',

    }
  </script>
  
  
  
  <style scoped>
    .space-align-container {
    display: flex;
    justify-content: center;
    align-items:first baseline;
    flex-wrap: wrap;
    
  }
  .space-align-block {
    margin: 8px 4px;
    border: 1px solid #ffffff;
    padding: 4px;
    flex: none;
    width:60%;
  }
  
  </style>
  
  