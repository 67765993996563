<template>
    
    <div class="space-align-container">
    <div class="space-align-block">            
      <a-card hoverable>
         <template #cover>
          <!--<img alt="DTU(Wireless)" src="assets/Products/DTU_1.jpg" style="width:50%"/>-->
          <a-image :width="800" src="./assets/Products/DTU_1.jpg" :preview="false"/>

        </template>
        <a-card-meta title="DTU(Data Transmission Unit)">
          <template #description>
            <p style="text-align:left"><strong>Robust Communication Solutions Tailored to Your Needs</strong>
              Our engineered devices for both short-range and wired communication set new standards in connectivity and reliability. 
              Whether you require RS-232/485 to Zigbee, RS-232/485 to Ethernet/WiFi, or other interface solutions, our products are the perfect choice.
            </p>  
            <p  style="text-align:left"><strong>Versatile Configuration Options</strong>
              Configuring our devices is a breeze, either through AT commands or a web server. 
              This allows you to customize settings to meet your specific requirements without the need for complex processes. 
              Comprehensive documentation is readily available to assist you in maximizing the use of our products.
            </p>  
            <p  style="text-align:left"><strong>Dependability You Can Trust</strong>
              Our devices are housed in robust enclosures designed to withstand even the harshest conditions. 
              Whether it's challenging industrial environments or outdoor settings, our products are engineered for reliable performance. 
              Moreover, we offer a wide input voltage range and protection against reverse power supply, ensuring the safety and efficiency of your systems.
            </p>
            <p  style="text-align:left"><strong>  The Ideal Solution for Your Applications</strong>
              Whether you operate in the industrial sector, building automation, or other fields, 
              our products offer the connectivity and reliability you need. 
              Rely on us to meet your communication requirements and enhance the efficiency of your systems.

            </p>
          </template>
       </a-card-meta>
      </a-card>
    </div>
    
    <div class="space-align-block">            
      <a-card hoverable>
         <template #cover>
          <!--<img alt="RFID(Reader&Writer)" src="assets/Products/RFID_01.jpg" style="width:50%"/>-->
          <a-image :width="800" src="./assets/Products/RFID_01.jpg" :preview="false"/>
        </template>
        <a-card-meta title="Data Acquistion Device">
          <template #description>
            <p style="text-align:left"><strong>Ultra High-Frequency RFID Reader/Writer</strong>
              The Ultra High-Frequency (UHF) RFID Reader/Writer is a versatile and compact data collection device designed for a wide range of applications. 
              This device is characterized by its USB-powered operation, portability, 
              adjustable read/write distance, extensive compatibility, 
              support for custom development, and independence from specific operating systems. 
              It finds applications in various fields, including asset management, time tracking, materials management, and warehouse inventory control.
            </p>  
            <p  style="text-align:left"><strong>Wide range of applications</strong>
                  Easily track and manage valuable assets, improving asset utilization and reducing losses. Streamline time and attendance tracking processes in various industries, 
                  enhancing workforce management. Efficiently manage materials and inventory in manufacturing and logistics, reducing costs and optimizing operations.
                  Improve inventory accuracy and streamline warehouse operations with real-time RFID data capture for inbound and outbound goods.
            </p>  
         

          </template>
       </a-card-meta>
      </a-card>
    </div>


    </div>
    
</template>

<script >


 export default {
    name: 'DTUModules',
    id: {
      type: String,
      required: false
    }

}


</script>

<style scoped>
 .space-align-container {
  display: flex;
  justify-content: center;
  align-items:first baseline;
  flex-wrap: wrap;
  
}
.space-align-block {
  margin: 8px 4px;
  border: 1px solid #ffffff;
  padding: 4px;
  flex: none;
  width: 70%;
  
}
</style>

