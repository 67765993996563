<template>
 
    <h1>General Terms and Conditions (GTC)</h1>
    
    <h2>1. Applicability</h2>
    <p>These General Terms and Conditions (hereinafter referred to as "GTC") apply to all contracts concluded 
        between H-2 Technik UG (hereinafter referred to as the "Seller") and its customers (hereinafter referred to as the "Customer") concerning the purchase 
        of products via the website [www.h-2technik.com] (hereinafter referred to as the "Website").</p>

    <h2>2. Conclusion of Contract</h2>
    <p>2.1. The presentation of products on the Website does not constitute a legally binding offer. The Customer makes a binding offer to purchase by completing the order.</p>
    <p>2.2. The Seller reserves the right to reject orders without providing reasons.</p>

    <h2>3. Prices and Payment</h2>
    <p>3.1. The prices on the Website are final prices and include applicable value-added tax.</p>
    <p>3.2. The Customer may make payment through PayPal. PayPal's terms of use apply.</p>

    <h2>4. Delivery and Shipping Costs</h2>
    <p>4.1. Delivery will be made to the delivery address provided by the Customer.</p>
    <p>4.2. Shipping costs are specified on the Website and will be displayed to the Customer before checkout.</p>
    <p>4.3. The delivery lead time is based on the theoretical lead time determined by the existing inventory. This delivery lead time may 
            be adjusted based on actual circumstances. If we are unable to ship according to the delivery lead time specified in the contract, we 
            will notify you in advance via email. In such a situation, you have the right to cancel your online order, and we will provide a full 
            refund.</p>

    <h2>5. Right of Withdrawal</h2>
    <p>Please refer to Righ of withdraw for detail.</p>

    <h2>6. Warranty and Liability</h2>
    <p>6.1. Statutory warranty provisions apply.</p>
    <p>6.2. The Seller is liable only for damages attributable to gross negligence or intent.</p>

    <h2>7. Data Protection</h2>
    <p>7.1. The Seller's privacy policy is available on the Website and governs the collection, processing, and use of personal data.</p>

    <h2>8. Final Provisions</h2>
    <p>8.1. German law applies.</p>
    <p>8.2. The place of jurisdiction for all disputes arising from this contract is Karlsruhe in Germany.</p>

</template>

<script>
  export default {
    name: 'GeneralTermsConditions',

  }
</script>
<style>
</style>