import {createStore} from 'vuex'
import { ProductsDBInstance } from "./ProductDBDataStruct";

/*********************global storage for cart items****************************/
const store = createStore({ 
    state() {
      return {cartItems: [], // Array für die im Warenkorb enthaltenen Produkte
              shippingCost: 0.00,
              agree_GTC_RW: false
             
      }
    },
    mutations: {
        addNewItem(state, CartItem/*payload*/) {
        state.cartItems.push(CartItem/*payload*/); // Füge ein Produkt zum Warenkorb hinzu
        //console.log("New item added:", CartItem);

      },
      
      //remove item by product name
      removeItemByName(state, prod_name) {
        const index = state.cartItems.findIndex(item => item.prod_name === prod_name);
        if (index !== -1) {
            state.cartItems.splice(index, 1); // Entferne das Element aus dem Warenkorb
            //console.log(prod_name + " found and deleted ! ")

            if (state.cartItems.length === 0) {
              state.shippingCost = 0.00;
            }
        }
        else{
          //console.log(prod_name + " ... not found ! ")
        }
      },
      
      //remove item by index
      removeItemByIndex(state, index) {
            state.cartItems.splice(index, 1); // Entferne das Element aus dem Warenkorb
            if (state.cartItems.length === 0) {
              state.shippingCost = 0.00;
            }
      },

      //set shipping cost
      setShippingCost(state, cost) {
        state.shippingCost = cost; // Setze die Versandkosten
      },

      //set if read the GTS and ReturnWith
      setReadGTS_RW(state, ifread) {
        state.agree_GTC_RW = ifread; // Setze die Versandkosten
      }

    },

    actions: {
        addNewItem({ commit }, CartItem) {
        commit('addNewItem', CartItem); // Aktion, um ein Produkt zum Warenkorb hinzuzufügen
      },
      
      removeItemByName({ commit }, prod_name) {
        commit('removeItemByName', prod_name); // Aktion, um ein Produkt aus dem Warenkorb zu entfernen
      },

      removeItemByIndex({ commit }, index) {
        commit('removeItemByIndex', index); // Aktion, um ein Produkt aus dem Warenkorb zu entfernen
      },
      setShippingCost({ commit }, cost) {
        commit('setShippingCost', cost); // Aktion, um die Versandkosten zu setzen
      },
      setReadGTS_RW({ commit }, ifread) {
        commit('setReadGTS_RW', ifread); // Aktion, um die Versandkosten zu setzen
      },

    },
    getters: {
        cartItems: state => state.cartItems, // Getter als Funktion definieren
        shippingCost: state => state.shippingCost, // Getter für die Versandkosten
        agree_GTC_RW: state => state.agree_GTC_RW,  

        cartSubtotal: state => {
          const subtotal = state.cartItems.reduce((total, item) => total + parseFloat(item.prod_subtotal), 0);
          const total = subtotal + state.shippingCost; // Versandkosten hinzufügen
          return total.toFixed(2); // Berechne die Summe und runde auf 2 Dezimalstellen
        },

        totalWeight: state=>{
          //console.log("cartItems:", state.cartItems);
          const tmp_weight = state.cartItems.reduce((total, item) => {
          const weight = parseFloat(item.prod_weight);
          //console.log("Item weight:", weight);
            return total + weight;
          }, 0);
          //console.log("Total weight:", tmp_weight);
          return tmp_weight.toFixed(3);
        },

        getLeadTime: state => {
          let tmp_leadtime = 0;
          let tmp_total_quantity = 0;
    
          state.cartItems.forEach((item) => {
            const leadTime = parseFloat(item.prod_leadtm);
            const quantity = parseInt(item.prod_quan);
    
            // Finde die längste Lieferzeit
            if (tmp_leadtime === 0) {
              tmp_leadtime = leadTime;
            } else {
              if (leadTime > tmp_leadtime) {
                tmp_leadtime = leadTime;
              }
            }
    
            // Zähle die Gesamtmenge der manuell hergestellten Produkte
            if (!item.is_standard) {
              tmp_total_quantity += quantity;
            }
          });
          
          //console.log("...lead time ...=" + tmp_leadtime);
          // Für manuell hergestellte Kabel, 10 Stück pro Tag als Standard
          if (tmp_total_quantity > 10) {
          
            tmp_leadtime = tmp_leadtime + ProductsDBInstance.HandProductionDay(tmp_total_quantity);
            return tmp_leadtime.toFixed(0);
          } else {
            return tmp_leadtime.toFixed(0);
          }
        },

        getAllProductsInStr: (state, getters) => {
          let result = '====>';
          state.cartItems.forEach((item, index )=> {
            result += `\n ${++index} .) ${item.prod_name} | Quantity:  ${item.prod_quan} | Subtotal(€): ${item.prod_subtotal} `;
          });
          const totalPrice = state.cartItems.reduce((total, item) => total + parseFloat(item.prod_subtotal), 0) + state.shippingCost;
          const leadTime = getters.getLeadTime;
          result += `\n....Shipping Cost(€): ${state.shippingCost.toFixed(2)}\n.... payed Total price(€): ${totalPrice.toFixed(2)} (19% VAT included)\n.... leadTime(Day): ${leadTime}`;
          return result;
        }
      }  
    }
);

/***********************definition of cart item*********************************/
class CartItem {

    constructor(prod_typ, is_standard, prod_name, prod_quan, prod_subtotal, prod_weight, prod_leadtm, prod_oth1, prod_oth2, prod_oth3,
                conn_A, conn_B, cable_type, cable_length) {
        this.prod_typ = prod_typ;   //1=coaxial assemble; 2=coaxial connector
        this.is_standard = is_standard;
        this.prod_name = prod_name; //product name
        this.prod_quan = prod_quan; //order quantity
        this.prod_subtotal = prod_subtotal;  //subtotal (with 19% VAT)
        this.prod_weight = prod_weight;      //weight in kg
        this.prod_leadtm = prod_leadtm;      //product leadtime 
        this.prod_oth1 = prod_oth1;          //reserved 1 
        this.prod_oth2 = prod_oth2;          //reserved 2 
        this.prod_oth3 = prod_oth3;          //reserved 3
        this.conn_A = conn_A;                //connector A 
        this.conn_B = conn_B;                //connector A 
        this.cable_type = cable_type;        //cable type
        this.cable_length = cable_length;    //cable length

    }
}


export { store, CartItem };
