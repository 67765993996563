<template>
    
    <div class="space-align-container">
     <div class="space-align-block">
      <a-card hoverable >
         <template #cover>
          <!--<a-img alt="example" src="./assets/Products/PCB_1.jpg" style="width:50%"/>-->
          <a-image  :width="800" src="./assets/Products/PCB_1.jpg" :preview="false"/>
        </template>
        <a-card-meta title="IoT Module for development">
          <template #description >
            <p style="text-align:left">
            Welcome to the world of IoT technology, where innovation meets simplicity! At our company, we believe in making IoT accessible to everyone. 
            Whether you're a tech enthusiast, a DIY hobbyist, or a professional looking to create smart solutions, our IoT offerings are designed to empower you.
            With IoT, you can transform your home into a smart, interconnected ecosystem. 
            It's a learning journey that's both fun and affordable. All you need are basic components like PCB modules and an intuitive Integrated Development Environment (IDE) 
            along with the corresponding libraries. We partner with popular chip manufacturers to ensure that you get the best hardware for your projects. 
            Our easy-start kits are designed for hassle-free setup, and our configurations are straightforward, even for beginners. The possibilities are endless with IoT. 
            From home automation to environmental monitoring, you can explore a wide range of applications. And if you need customized firmware, we've got you covered. 
            Join us on this exciting IoT adventure and bring your ideas to life. Discover the convenience, creativity, and connectivity that IoT has to offer!
            </p>
          </template>
       </a-card-meta>
      </a-card>
    </div>
    <div class="space-align-block">
      <a-card hoverable >
         <template #cover>
          <!--<img alt="example" src="assets/Products/PCB_4.jpg" style="width:50%"/>-->
          <a-image  :width="800" src="./assets/Products/PCB_4.jpg" :preview="false"/>
        </template>
        <a-card-meta title="Debugger Tools for Seamless Development">
          <template #description>
            <p style="text-align:left">
            Our cutting-edge debugger tools provide you with the insights you need to fine-tune your IoT applications. 
            With real-time debugging capabilities, you can identify and resolve issues swiftly, ensuring that your projects run smoothly.
            Stay ahead of the curve with our online debugging tools. Monitor and fine-tune your IoT applications remotely, ensuring they perform at their best even when you're not on-site. 
            This capability streamlines troubleshooting and minimizes downtime.
          </p>
          </template>
       </a-card-meta>
      </a-card>
    </div>
    <div class="space-align-block">
      <a-card hoverable>
         <template #cover>
          <!--<img alt="example" src="assets/Products/PCB_5.jpg" style="width:50%"/>-->
          <a-image  :width="800" src="./assets/Products/PCB_5.jpg" :preview="false"/>
        </template>
        <a-card-meta title="Peripheral Equipment to Enhance Functionality">
          <template #description>
            <p style="text-align:left">
           Explore our array of peripheral equipment designed to expand the capabilities of your IoT devices. 
           From sensors to actuators, we offer a variety of options to suit your specific application needs. 
           These peripherals seamlessly integrate into your projects, allowing you to unleash the full potential of your creations.
           </p>
          </template>
       </a-card-meta>
      </a-card>
    </div>

    
  </div>
    
</template>

<script >


 export default {
    name: 'IoTModule',

}


</script>

<style scoped>
 .space-align-container {
  display: flex;
  justify-content: center;
  align-items:first baseline;
  flex-wrap: wrap;
  
}
.space-align-block {
  margin: 8px 4px;
  border: 1px solid #ffffff;
  padding: 4px;
  flex: none;
  width:70%;
}
</style>

