import { createStore } from 'vuex';

// Klasse für Lieferadresse
class DeliveryAddress {
  constructor(Name1, Name2, Name3, Street, HouseNr, Region, PostCode, Country, Email, Phone) {
    this.Name1 = Name1;
    this.Name2 = Name2;
    this.Name3 = Name3;
    this.Street = Street;
    this.HouseNr = HouseNr;
    this.Region = Region;
    this.PostCode = PostCode;
    this.Country = Country;
    this.Email = Email;
    this.Phone = Phone;
  }
}

// Vuex Store für AddressBook
const AddressBook = createStore({
  state() {
    return {
      address: new DeliveryAddress('', '', '', '', '', '', '', '', '', '') // Initialisieren Sie die Adresse mit leeren Strings
    };
  },
  mutations: {
    SET_ADDRESS(state, payload) {
      state.address = payload;
    },
    UPDATE_ADDRESS_FIELD(state, { field, value }) {
      if (state.address.hasOwnProperty(field)) {
        state.address[field] = value;
      }
    },

    CLEAR_ADDRESS(state) {
      state.address = new DeliveryAddress('', '', '', '', '', '', '', '', '', '');
    }
  },
  actions: {
    setAddress({ commit }, address) {
      commit('SET_ADDRESS', address);
    },
    updateAddressField({ commit }, { field, value }) {
      commit('UPDATE_ADDRESS_FIELD', { field, value });
    },
    clearAddress({ commit }) {
      commit('CLEAR_ADDRESS');
    }
  },
  getters: {
    address: (state) => state.address
  }
});

export { AddressBook, DeliveryAddress };
