import $ from 'jquery';
export function SendAjaxRequest(data, successCallback, errorCallback) {
    $.ajax({
      type: "POST",
      url: "https://h-2technik.com/exe/mailsender.php",
      data: data,
    })
      .done(function () {
        successCallback();
      })
      .fail(function () {
        errorCallback();
      });
  }

export function EmailSignature(){

   const str = " * Our GTC(General Terms and Conditions) download link is  https://h-2technik.com/online/widerrufsrecht/General%20Terms%20and%20Conditions%20(GTC).pdf\n" + 
               " * Our Right of Withdrawal download link is https://h-2technik.com/online/widerrufsrecht/H-2Technk_Widerrufsbelehrung(EN).pdf\n" + 
               " * Our Withdrawl Formular download link is https://h-2technik.com/online/widerrufsrecht/H-2Technk_Widerrufsbelehrung_formular(EN).pdf \n\n\n" +
               "-------------------------------------------\n" + 
               "H-2 Technik UG\n Donauschwabenstr. 13,\n"+
               "76149, Karlsruhe, Germany\n" +
               "Tel: +49 721 6624655 \n" 
               "Email:contact@h-2technik.com\n" + 
               "-------------------------------------------\n";
    return str;
}


export function validateEmail(str) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(str);
}

export  function validatePhone(str) {
  const phoneRegex = /^(\+\d{9,13}|\d{9,13})$/;
  return phoneRegex.test(str);
}

export  function validateEmptyInput(str) {
  const subjectRegex = /\S+/;
  //console.log("passed value str =" + str);
  return subjectRegex.test(str);

}

export function checkShippingAddress(arr){

  const name_err_msg= " cannot be empty and cannot contain non-alphabetic characters, except for hyphens(-)!";
  const place_err_msg= "Region cannot be empty and must not include any non-alphabetic characters, except for spaces, hyphens(-), and commas(,) !";
  const name_pattern = /^[A-Za-zÀ-ÖØ-öø-ÿ-]+$/;
  const place_pattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s,-]+$/;

  
  var ret_val="-";
  if (!validateEmptyInput(arr[0].name1) || ! name_pattern.test(arr[0].name1) ) {
    ret_val += "\n name1" + name_err_msg ;
    return ret_val;
  }
  
  if (!validateEmptyInput(arr[0].name2) || ! name_pattern.test(arr[0].name2)) {
    ret_val += "\n name2" + name_err_msg ;
     return ret_val;
  }
  if (!validateEmptyInput(arr[0].street)) {
    ret_val += "\n street cannot be empty！" ;
    return ret_val;
  }
  if (!validateEmptyInput(arr[0].region) || ! place_pattern.test(arr[0].region)) {
    ret_val += "\n " + place_err_msg ;
    return ret_val;
  }
  if (!validateEmptyInput(arr[0].postcode)) {
    ret_val += "\n postcode cannot be empty！" ;
    return ret_val;
  }
  if (!validateEmptyInput(arr[0].country)) {
    ret_val += "\n country cannot be empty！" ;
    return ret_val;
  }
  if (!validateEmail(arr[0].email)) {
    ret_val += "\n email format is incorrect! " ;
  }

  const phonePattern = /^\d{1,14}$/;
  if (!validateEmptyInput(arr[0].phone)  || !phonePattern.test(arr[0].phone)) {
    ret_val += "\n The phone number cannot be empty and must conform to the following format: countrycode+phonenumber ！ " ;
  }

  //console.log(ret_val);
  return ret_val;
}

export function generateUniqueKey() {
  const now = new Date();
  const year = String(now.getFullYear()).slice(-2); // Letzte zwei Ziffern des Jahres
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Aktueller Monat (01-12)
  const day = String(now.getDate()).padStart(2, '0'); // Aktueller Tag (01-31)
  const hours = String(now.getHours()).padStart(2, '0'); // Aktuelle Stunden (00-23)
  const minutes = String(now.getMinutes()).padStart(2, '0'); // Aktuelle Minuten (00-59)
  const seconds = String(now.getSeconds()).padStart(2, '0'); // Aktuelle Sekunden (00-59)

  // Kombinieren Sie die Werte, um einen eindeutigen Schlüssel zu erstellen
  const uniqueKey = `${year}` + "-" +`${month}${day}${hours}${minutes}${seconds}`;
  //console.log(uniqueKey);
  return uniqueKey;

}



