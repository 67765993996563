import $ from 'jquery';


class ShippingData{

    static EU_COUNTRIES=[{ value: 'AT', label: 'Austria' },  
                         { value: 'BE', label: 'Belgium' },
                         { value: 'BG', label: 'Bulgaria' },
                         { value: 'HR', label: 'Croatia' },
                         { value: 'CY', label: 'Cyprus' },
                         { value: 'CZ', label: 'Czech Republic' },
                         { value: 'DK', label: 'Denmark' },
                         { value: 'EE', label: 'Estonia' },
                         { value: 'FI', label: 'Finland' },
                         { value: 'FR', label: 'France' },
                         { value: 'DE', label: 'Germany' },
                         { value: 'GR', label: 'Greece' },
                         { value: 'HU', label: 'Hungary' },
                         { value: 'IE', label: 'Ireland' },
                         { value: 'IT', label: 'Italy' },
                         { value: 'LV', label: 'Latvia' },
                         { value: 'LT', label: 'Lithuania' },
                         { value: 'LU', label: 'Luxembourg' },
                         { value: 'MT', label: 'Malta' },
                         { value: 'NL', label: 'Netherlands' },
                         { value: 'PL', label: 'Poland' },
                         { value: 'PT', label: 'Portugal' },
                         { value: 'RO', label: 'Romania' },
                         { value: 'SK', label: 'Slovakia' },
                         { value: 'SI', label: 'Slovenia' },
                         { value: 'ES', label: 'Spain' },
                         { value: 'SE', label: 'Sweden' }];


   static NON_EU=[       { value: 'AU', label: 'Australia' },
                         { value: 'CA', label: 'Canada' },
                         { value: 'CN', label: 'China' },
                         { value: 'IN', label: 'India' },
                         { value: 'CH', label: 'Switzerland' },
                         { value: 'NO', label: 'Norway' },
                         { value: 'GB', label: 'United Kingdom' },
                         { value: 'US', label: 'USA' }];

    static SHIPPING_DHL = [
                           { value: 0, label: '-', maxW: 0},
                           { value: 3.59, label: 'Within Germany <1kg DHL Small Parcel', maxW: 1.0 },
                           { value: 5.49, label: 'Within Germany 1~5kg DHL Parcel', maxW: 5.0 },
                           { value: 10.49, label: 'Within Germany >5kg DHL Parcel', maxW: 31.0},
                           { value: 5.25, label: 'EU Countries <1kg DHL Small Parcel', maxW: 1.0},
                           { value: 14.49, label: 'EU Countries 1~2kg DHL Parcel', maxW: 2.0},
                           { value: 16.49, label: 'EU >2kg DHL Parcel', maxW: 5.0},
                           { value: 5.99, label: 'Non-EU Countries <1kg DHL Small Parcel', maxW: 1.0},
                           { value: 49.99, label: 'Non-EU Countries 1~5kg DHL Parcel', maxW: 5.0}
                           ];

    constructor(){
        this._SHIPPING_DHL = [];
		this._EU_COUNTRIES = [];
        this._NON_EU = [];
		this._Address=["","","","","","","","","",""];
        //this.path = "http://192.168.178.1/pigtail50_exe/";
        this.path = "https://www.h-2technik.com/pigtail50_exe/";
        
    }
    
    //initate local liste of shipping way and countries
	fetchData(what, str) {
		const self = this; // Speichern Sie eine Referenz auf "this"
        const lw = what;
        let  parameters={material: lw, detail:str};
        
        return new Promise(function(resolve, reject) {
			$.ajax({
				type:"POST",
				url: self.path + "fetchmaterials.php",
				data: parameters,
				})
				.done(function (response) {
					resolve(response); 
				})
				.fail(function(response){
                    reject(response); 
				});
           });

		/*$.ajax({
			type:"POST",
			url: self.path + "fetchmaterials.php",
			data: {material: what},
			})

			.done(function (response) {
				//console.log("O.K. ---------------" + response);
				try{
					var data = JSON.parse(response);
					
					if (lw === 5){ //countries
                        this._EU_COUNTRIES = data;
					}
                   if (lw === 6){ //shipping way
                      this._NON_EU = data;
					}
                    if (lw === 7){ //shipping way
                      this._SHIPPING_DHL = data;
					}
				}catch(e){
					console.error("Error: interprete returned json data, default values are used !");  	
				}
            })
           .fail(function () {
                console.error("Error: reading material parameters, default values are used !");  
                if (lw === 5 ){ //countries
                    this._EU_COUNTRIES = ShippingData.EU_COUNTRIES;
                }
                if (lw === 6){ //shipping way
                  this._NON_EU = ShippingData.NON_EU;
                }
                if (lw === 7){ //shipping way
                  this._SHIPPING_DHL = ShippingData.SHIPPING_DHL;
                }

           });*/
    }
    


    findAvailableShippingCostLocal(weight, country){
        let c = this.determinRegion(country);
        
        //germany
        if (c === 1){
            if (weight < ShippingData.SHIPPING_DHL[1].maxW) return  ShippingData.SHIPPING_DHL[1].value;
            if (weight > ShippingData.SHIPPING_DHL[1].maxW && weight < ShippingData.SHIPPING_DHL[2].maxW)   return  ShippingData.SHIPPING_DHL[2].value; 
            if (weight > ShippingData.SHIPPING_DHL[2].maxW) return  ShippingData.SHIPPING_DHL[3].value;
        }
        //EU
        else if (c === 2){
            if (weight < ShippingData.SHIPPING_DHL[4].maxW) return  ShippingData.SHIPPING_DHL[4].value;
            if (weight > ShippingData.SHIPPING_DHL[4].maxW && weight < ShippingData.SHIPPING_DHL[5].maxW)   return  ShippingData.SHIPPING_DHL[5].value; 
            if (weight > ShippingData.SHIPPING_DHL[5].maxW) return  ShippingData.SHIPPING_DHL[6].value;
        }
        //other countries
        else if (c === 3){
            if (weight < ShippingData.SHIPPING_DHL[7].maxW) return  ShippingData.SHIPPING_DHL[7].value;
            if (weight > ShippingData.SHIPPING_DHL[7].maxW && weight < ShippingData.SHIPPING_DHL[8].maxW)   return  ShippingData.SHIPPING_DHL[8].value; 
        }
        else{
            return 49.99;
        }

    }
    findAvailableShippingCost(weight, country){
        
        let c = this.determinRegion(country);
        //console.log("Country: " + c + "-" + country + "/Weight: " + weight);
        //germany
        if (c === 1 || c === 0 /*region code=0 as default*/){
            if (weight < this._SHIPPING_DHL[1].maxW) return  this._SHIPPING_DHL[1].value;
            if (weight > this._SHIPPING_DHL[1].maxW && weight < this._SHIPPING_DHL[2].maxW)   return  this._SHIPPING_DHL[2].value; 
            if (weight > this._SHIPPING_DHL[2].maxW) return  this._SHIPPING_DHL[3].value;
        }
        //EU
        else if (c === 2){
            if (weight < this._SHIPPING_DHL[4].maxW) return  this._SHIPPING_DHL[4].value;
            if (weight > this._SHIPPING_DHL[4].maxW && weight < this._SHIPPING_DHL[5].maxW)   return  this._SHIPPING_DHL[5].value; 
            if (weight > this._SHIPPING_DHL[5].maxW) return  this._SHIPPING_DHL[6].value;
        }
        //other countries
        else if (c === 3){
            if (weight < this._SHIPPING_DHL[7].maxW) return  this._SHIPPING_DHL[7].value;
            if (weight > this._SHIPPING_DHL[7].maxW && weight < this._SHIPPING_DHL[8].maxW)   return  this._SHIPPING_DHL[8].value; 
        }
        else{
            return 49.99;
        }

    }

    determinRegion(name){
        if (name === "Germany") {
            return 1;
        } else if (this.isInArray(name, this._EU_COUNTRIES)) {
            return 2;
        } else if (this.isInArray(name, this._NON_EU)) {
            return 3;
        }
        // Wenn der Name weder "Germany" noch in EU_COUNTRIES noch in NON_EU ist, gib 0 zurück oder eine andere geeignete Standardantwort.
        return 0;
    }

    isInArray(name, countriesArray) {
        return countriesArray.some(country => country.label === name);
    }

    findShippingWayByValue(value) {
        const shippingMethod = this._SHIPPING_DHL.find(method => method.value === value);
        //console.log(shippingMethod);
        return shippingMethod ? shippingMethod.label : null;
      }

    static getCountryCode(label) {
        let country = ShippingData.EU_COUNTRIES.find(country => country.label === label);
        if (country) {
            return country.value;
        }
        country = ShippingData.NON_EU.find(country => country.label === label);
        return country ? country.value : null;
    } 

}

const shippins = new ShippingData();
export{ShippingData, shippins};
