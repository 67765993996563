import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css'
import {store} from './DataModel/CartItemDB'


const app = createApp(App);

// Code zur Unterdrückung der Warnung hinzufügen
const resizeObserverLoopErrSilencer = () => {
    let resizeObserverErr = false;
    const resizeObserverLoopErr = () => {
        if (resizeObserverErr) {
            return;
        }
        resizeObserverErr = true;
        console.warn('ResizeObserver loop completed with undelivered notifications');
    };
    window.addEventListener('error', resizeObserverLoopErr);
    return () => {
        window.removeEventListener('error', resizeObserverLoopErr);
    };
};

resizeObserverLoopErrSilencer();


app.use(store);
app.use(Antd).mount('#app');