<template>
    <a-layout class="layout" >
    
    <a-layout-content style="padding: 0 20px">
     
    <div class="space-align-container">
      <a-card style="margin: 10px;" hoverable >
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Step 1</label>
        <a-input-group compact>
          <a-input :value="ConnA_label"   style="width: 35%; " size="large"/>
          <a-select v-model:value="selectConnectorA" style="width:180px" size="large" @change="selMaterialAChanged" :options="selectConnABTypeOptions" :disabled="input_disable"  ref="connector_a" required>
           <!--a-select-option v-for="option in selectConnABOptions" :key="option.value" :value="option.value">
              {{ option.label }}
           </a-select-option-->
          </a-select>
        </a-input-group>  
        <a-space-compact block>
          <span class="mock-block">
            <b>Tech. Data:</b> <br>
            Impedance : <label style="font-color:red; font-weight: bold;">{{connector_a_impedance}}</label>Ω<br>
            Material: {{connector_a_material}}<br>
            Range of Freq: {{connector_a_wkfreq}}<br>
          </span>
          <a-image alt=" " :src="selectConnectorA_Img" style="height:100px"/>

        </a-space-compact>
        
       </a-space>
      
      </div>
    </a-card>

      <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Step 2</label>
        <a-input-group compact>
          <a-input :value="CableType_label"  style="width: 20%;" size="large"/>
          <a-select v-model:value="selectCableType" style="width:160px" size="large" @change="selMaterialCableChanged" :options="selectCableOptions" :disabled="input_disable"  ref="cable" required>
           <!--a-select-option v-for="option in selectCableOptions" :key="option.value" :value="option.value">
              {{ option.label }}
           </a-select-option-->
          </a-select>
          <a-input-number v-model:value="cableLength" addonBefore="Length" min="3" max="100" size="large" style="width:30%" addon-after="cm" :disabled="input_disable"  ref="cable_length">
           <!--<template #addonAfter>
             <a-select v-model:value="lengthUint" style="width: 60px" :options="selectUnitOptions" @change="selUnitChanged">
             a-select-option value="cmeter">cm</a-select-option>
             a-select-option value="meter">m</a-select-option>
             </a-select>
            </template>-->
          </a-input-number>

        </a-input-group>  
        <a-space-compact block>
          <span class="mock-block">
            <b>Tech. Data:</b> <br>
            Impedance: <label style="font-color:red; font-weight: bold;">{{ cable_impedance }}</label>Ω<br> 
            Range of Freq: {{ cable_wkfreq }}<br> 
            Material: {{ cable_material }} <br>
            Color: {{cable_color}}<br>
          </span>  
          
          <a-image  alt=" " :src="selectCableType_Img" style="height:100px; "/>

        </a-space-compact>
       </a-space>
      </div>
    </a-card>



    <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Step 3</label>

          <a-input-group compact>
            <a-input :value="ConnB_label"  style="width: 38%;" size="large"/>
            <a-select v-model:value="selectConnectorB" style="width:180px" size="large" @change="selMaterialBChanged" :options="selectConnABTypeOptions" :disabled="input_disable"  ref="connector_b" required>
              <!--a-select-option v-for="option in selectConnABOptions" :key="option.value" :value="option.value">
                 {{ option.label }}
              </a-select-option-->
            </a-select>
          </a-input-group>

          <a-space-compact block>
          <span class="mock-block">
            <b>Tech. Data:</b> <br>
            Impedance : <label style="font-color:red; font-weight: bold;">{{connector_b_impedance}}</label>Ω<br>
            Material: {{connector_b_material}}<br>
            Range of Freq: {{connector_b_wkfreq}}<br>
          </span>
            <a-image alt=" " :src="selectConnectorB_Img" style="height:100px; /*show reversed picture transform: scaleX(-1) */"/>

          </a-space-compact>



        </a-space>
      </div>
      </a-card>

      <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center"  size="large">
          <label style="font-weight: bold;">Step 4</label>
         <a-input-number v-model:value="amount" addonBefore="Quantity" size="large" min="1" max="10" style="width:67%;" :disabled="input_disable"  ref="quantity"></a-input-number>
         <a-space size="large">
          <a-button type="primary" style="margin: 8px" @click='calculate' :disabled="addprd_disabled">Inqury</a-button>
          <a-button type="primary" @click="resetInput" :disabled="addprd_disabled">Reset</a-button>
         </a-space>
        </a-space>
      </div>
    </a-card>

  <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Inqury Results</label>
         <a-input-group >
          <a-input-number :value="price" addonBefore="Price" size="large" addon-after="€ (inkl. 19% V.A.T)" style="width:75%;" disabled ></a-input-number>
          <br />
          <a-input-number v-model:value="weight" addonBefore="Weight" size="large" addon-after="Kg" style="width:75%;" disabled></a-input-number>
          <br />
          <a-input-number v-model:value="time" addonBefore="Lead Time" size="large" addon-after="Day" style="width:75%;" disabled></a-input-number>
          
         </a-input-group>

         <a-space size="large">
          <a-button type="primary" @click="addToCart" :disabled="addprd_disabled">Add to Cart</a-button>
        </a-space>


       </a-space>
      </div>
      </a-card>

      <a-card style="margin: 10px" hoverable>
        <div>
          <a-space direction="vertical" align="center" size="large">
           <ShoppingCardCheckout  ref='ShoppingCardCheckout' @openGTCorRWDEvent="handleopenGTCorRW" @resetUserInput="resetInput"/>
          </a-space>
        </div>
      </a-card>

    
    </div>
    
   

    </a-layout-content>
    </a-layout>
    
    <!-- Page assistant information block  -->
    <a-modal v-model:open="open_msg_wnd" :title="msg_title" @ok="closeMessageWnd">
      <span style="white-space: pre-line;">{{ atten_msg }}</span>
      <!--p>{{ atten_msg }}</p-->
     
    </a-modal>

    <a-drawer
       v-model:open="open_GTC"
       class="custom-class"
       root-class-name="root-class-name"
       :root-style="{ color: 'blue' }"
       style="color: black"
       :title="draw_title"
      placement="right"
      @close="scrollToShoppingCart"
     >
      <component :is="currentComponent" />
     </a-drawer>

</template>


<script >

import ShoppingCardCheckout from './Checkout2.vue';
import GeneralTermsConditions from './GeneralTermsConditions.vue';
import RightWithdraw from './RightWithdraw.vue';
import { ref } from 'vue';
import { ProductsDBInstance } from "@/DataModel/ProductDBDataStruct";
import { SendAjaxRequest, EmailSignature } from "@/DataModel/JsFunctions";
import { markRaw } from 'vue';

export default {
  name: 'CableAssemble',
  props: {
    msg: String,
    id: {
      type: String,
      required: false
    }
  },
  components: {
    GeneralTermsConditions:markRaw(GeneralTermsConditions), //GTC template
    RightWithdraw:markRaw(RightWithdraw),      //right of withdraw  
    ShoppingCardCheckout: markRaw(ShoppingCardCheckout),
  },

  data() {
    return {
      
      detail_shoppingcard_visable:ref(true),

      //productions: new Productions(),
      //productionsDB: new ProductionsDB(),
      //shippings: new ShippingData(),
      selectConnectorA: '',
      selectConnectorB: '',
      selectCableType: '',

      CConnertorA: ref(null),
      connector_a_impedance: '',
      connector_a_material: '',
      connector_a_wkfreq:'',
      connector_b_impedance: '',
      connector_b_material: '',
      connector_b_wkfreq:'',

      cable_impedance:'',
      cable_wkfreq:'',
      cable_material:'',
      cable_color:'',

      assetsBase: './',
      ConnA_label: 'Connector A',
      CableType_label: 'Coaxial Type',
      ConnB_label: 'Connector B',
      
      
      selectConnectorA_Img: './assets/connector/no.png',
      selectConnectorB_Img: './assets/connector/no.png',
      selectCableType_Img:  './assets/connector/no.png',
      price: 0.0,
      cableLength: 0,
      amount: 0,
      time: 0,
      weight: 0,

      user_agree_cond: ref(false),    //boolean, general termins and condition & right of withdraw
      //ShoppingCartTable: ref(null),  //child component
      PaypalBlock: ref(null),       //child component 
      
      input_disable: ref(false),
      addprd_disabled:ref(false),
      payment_visable: ref(false),
      open_msg_wnd:ref(false),
      open_GTC: ref(false),
      draw_title:"",
      disable_chk_user_agree:ref(false),
      atten_msg:``,
      msg_title:"",
    

      selectConnABOptions: [],
      selectConnABTypeOptions: [],
      selectCableOptions: [],
      selectUnitOptions: [
        { value: 'cm', label: 'cm'},
        { value: 'm', label: 'm' }
      ],

      debug: 0,
    };
  },

  /*beforecreate(){
   
  },*/

  mounted() { 
    
    //*********************************************************18.01.2024 copy connector info from ProductsDBInstance to local selectConnABOptions
    for(var i = 0; i < ProductsDBInstance.connectors.length; i++)
    {
      this.selectConnABOptions.push({value: ProductsDBInstance.connectors[i].Name, 
                                     label: ProductsDBInstance.connectors[i].Name, 
                                     img: ProductsDBInstance.connectors[i].TData.Picture});
    }
    //console.log(this.selectConnABOptions.length);
    this.initVlaue();
    
  },

  methods: {


    initVlaue () {
      this.sortenMaterial(this.selectConnABOptions);
    },
    //Sorten the productions
    sortenMaterial(options) {
      //console.log('sortenMaterial---');
      var types = [];
      for(var i = 0; i < options.length; i++){
        const type = options[i].value.split('-')[0];
        var newEle = true;
        for(var j = 0; j < types.length; j++){
          if(types[j] == type){
            newEle = false;
            break;
          }
        }
        if(newEle){
          var optiosTypes = [];
          for(var n=i; n < options.length; n++){
            if(options[n].value.split('-')[0] == type)
              optiosTypes.push(options[n]);
        }
          
          var ele = {label: this.evaluateTypeString(type), options: optiosTypes};
          this.selectConnABTypeOptions.push(ele);
          //console.log(ele);
          types.push(type);
        }
      }

      this.selectConnABTypeOptions.sort((a, b) => {
        const labelA = a.label.toUpperCase(); // Für Groß-/Kleinschreibung ignorieren
        const labelB = b.label.toUpperCase(); // Für Groß-/Kleinschreibung ignorieren
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
     }); 

    },

    evaluateTypeString(typeStr) {
     
      if (typeStr  === 'empty') {
          return "-No connector";   
      }

      if (typeStr.charAt(0) === 'R') {
          return typeStr.slice(1) + "(Reversed)";
          
      } else {
          return typeStr;
      }
    },

    // Event handler on change Connector A
    selMaterialAChanged (value) {
      ////console.log(`selected connectorA ${value}`);
      //////console.log(`selected selectConnectorA ${this.selectConnectorA}`);
      this.selectConnectorA = value;
      
      //const cconnector = this.productionsDB.connectors.find(
      //    (connector) => connector.Name === this.selectConnectorA
      //);
      const cconnector = ProductsDBInstance.connectors.find(
          (connector) => connector.Name === this.selectConnectorA
      );

      if(cconnector){
       
        this.selectConnectorA_Img = this.assetsBase + cconnector.TData.Picture //this.assetsBase + selectedOption.img;
        this.selectConnectorA_Img = this.assetsBase + cconnector.TData.Picture //this.assetsBase + selectedOption.img;
        this.connector_a_impedance =  cconnector.TData.Impedance;
        this.connector_a_material = cconnector.TData.Material;
        this.connector_a_wkfreq = cconnector.TData.WorkFreq;
        
        
        const avaliable_calbes = cconnector.SuitableCable;
        this.selectCableOptions.length = 0;
        this.selectCableOptions.push({value: '-', label: '-', img: ''});
        if (this.selectConnectorB === '' || this.selectConnectorB === 'empty') {
          if(avaliable_calbes.length == 0){
            this.selectCableType = '';
            this.selectCableType_Img = '';
            this.cable_impedance =  '--';
            this.cable_wkfreq =  '--';
            this.cable_material = '--';
            this.cable_color = '--';
          }
          else{
            avaliable_calbes.forEach((cable) => {
                  const find_cable = ProductsDBInstance.cables.find(
                      (cal) => cal.Name === cable
                  );

                  //const find_cable = this.productionsDB.cables.find(
                  //    (cal) => cal.Name === cable
                  //);

                  if (find_cable) {
                    ////console.log("found calbe: " + cable);
                    this.selectCableOptions.push({
                      value: find_cable.Name,
                      label: find_cable.Name,
                      //img: find_cable.TData[6].Picture
                      img: find_cable.TData.Picture
                    });
                  }

                }
            );
          }
        }
        else {
          const cconnectorB = ProductsDBInstance.connectors.find(
              (connector) => connector.Name === this.selectConnectorB
          );
          //const cconnectorB = this.productionsDB.connectors.find(
          //    (connector) => connector.Name === this.selectConnectorB
          //);

          if (cconnectorB) {
            const avaliable_calbes_B = cconnectorB.SuitableCable;
            if(avaliable_calbes.length == 0) {
              avaliable_calbes_B.forEach((cable) => {
                    
                const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === cable
                    //);
                    if (find_cable) {
                      ////console.log("found calbe: " + cable);
                      this.selectCableOptions.push({
                        value: find_cable.Name,
                        label: find_cable.Name,
                        //img: find_cable.TData[6].Picture
                        img: find_cable.TData.Picture
                      });
                    }
                  }
              );
            }
            else {
              if (avaliable_calbes.length <= avaliable_calbes_B.length ) {
                avaliable_calbes.forEach((cable) => {
                  const find_same_cable = avaliable_calbes_B.find(
                      (cal) => cal === cable
                  );
                  if (find_same_cable) {
                    const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === find_same_cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === find_same_cable
                    //);
                    this.selectCableOptions.push({
                      value: find_cable.Name,
                      label: find_cable.Name,
                      //img: find_cable.TData[6].Picture
                      img: find_cable.TData.Picture
                    });
                  }
                });
              }
              else {
                avaliable_calbes_B.forEach((cable) => {
                  const find_same_cable = avaliable_calbes.find(
                      (cal) => cal === cable
                  );
                  if (find_same_cable) {
                    const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === find_same_cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === find_same_cable
                    //);
                    this.selectCableOptions.push({
                      value: find_cable.Name,
                      label: find_cable.Name,
                      //img: find_cable.TData[6].Picture
                      img: find_cable.TData.Picture
                    });
                  }
                });
              }
            }

            ////console.log('check if selectCableType: '+ this.selectCableType + ' is availabe for this connector.');
            const index_cable = this.selectCableOptions.find(
                (cal) => cal.value === this.selectCableType
            );
            if( index_cable == undefined) {
              //console.log('selectCableType is not availabe for this connector.');
              this.selectCableType = '';
              this.selectCableType_Img = '';
              this.cable_impedance =  '--';
              this.cable_wkfreq =  '--';
              this.cable_material = '--';
              this.cable_color = '--';
            }
          }
        }
      }
      else {
        // Wenn keine Übereinstimmung gefunden wurde, setze das Bild auf einen Standardwert oder lasse es leer
          this.selectConnectorA_Img = '';
       }
      
      //console.log(`selected connectorA ${this.selectConnectorA_Img}`);
    },

    selMaterialBChanged (value) {
      //console.log(`selected connectorB ${value}`);
      this.selectConnectorB = value;
    

      const cconnector = ProductsDBInstance.connectors.find(
          (connector) => connector.Name === this.selectConnectorB
      );

      //const cconnector = this.productionsDB.connectors.find(
      //    (connector) => connector.Name === this.selectConnectorB
      //);

      if(cconnector){
        this.selectConnectorB_Img = this.assetsBase + cconnector.TData.Picture //this.assetsBase + selectedOption.img;
        this.connector_b_impedance =  cconnector.TData.Impedance;
        this.connector_b_material = cconnector.TData.Material;
        this.connector_b_wkfreq = cconnector.TData.WorkFreq;
        const avaliable_calbes = cconnector.SuitableCable;
        this.selectCableOptions.length = 0;
        this.selectCableOptions.push({ value: '-', label: '-', img:'' });
        if (this.selectConnectorA === '' || this.selectConnectorA === 'empty') {
          if(avaliable_calbes.length == 0){
            this.selectCableType = '';
            this.selectCableType_Img = '';
            this.cable_impedance =  '--';
            this.cable_wkfreq =  '--';
            this.cable_material = '--';
            this.cable_color = '--';
          }
          else{
            avaliable_calbes.forEach( (cable) => {
              const find_cable = ProductsDBInstance.cables.find(
                  (cal) => cal.Name === cable
              );
              //const find_cable = this.productionsDB.cables.find(
              //    (cal) => cal.Name === cable
              //);
              if(find_cable)
                this.selectCableOptions.push({value: find_cable.Name,
                  //label:find_cable.Name, img: find_cable.TData[6].Picture});
                  label:find_cable.Name, img: find_cable.TData.Picture});
            }
            );
          }
        }
        else {

          const cconnectorA = ProductsDBInstance.connectors.find(
              (connector) => connector.Name === this.selectConnectorA
          );
          //const cconnectorA = this.productionsDB.connectors.find(
          //    (connector) => connector.Name === this.selectConnectorA
          //);

          if (cconnectorA) {
            const avaliable_calbes_A = cconnectorA.SuitableCable;
            if(avaliable_calbes.length == 0) {
              avaliable_calbes_A.forEach( (cable) => {
                    const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === cable
                    //);
                    if(find_cable)
                      this.selectCableOptions.push({value: find_cable.Name,
                        //label:find_cable.Name, img: find_cable.TData[6].Picture});
                        label:find_cable.Name, img: find_cable.TData.Picture});
                  }
              );
            }
            else {
              if (avaliable_calbes.length <= avaliable_calbes_A.length ) {
                avaliable_calbes.forEach((cable) => {
                  const find_same_cable = avaliable_calbes_A.find(
                      (cal) => cal === cable
                  );
                  if (find_same_cable) {
                    const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === find_same_cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === find_same_cable
                    //);
                    this.selectCableOptions.push({
                      value: find_cable.Name,
                      label: find_cable.Name,
                      //img: find_cable.TData[6].Picture
                      img: find_cable.TData.Picture
                    });
                  }
                });
              }
              else {
                avaliable_calbes_A.forEach((cable) => {
                  const find_same_cable = avaliable_calbes.find(
                      (cal) => cal === cable
                  );
                  if (find_same_cable) {
                    const find_cable = ProductsDBInstance.cables.find(
                        (cal) => cal.Name === find_same_cable
                    );
                    //const find_cable = this.productionsDB.cables.find(
                    //    (cal) => cal.Name === find_same_cable
                    //);
                    this.selectCableOptions.push({
                      value: find_cable.Name,
                      label: find_cable.Name,
                      //img: find_cable.TData[6].Picture
                      img: find_cable.TData.Picture
                    });
                  }
                });
              }
            }

            //console.log('check if selectCableType: '+ this.selectCableType + ' is availabe for this connector.');
            const index_cable = this.selectCableOptions.find(
                (cal) => cal.value === this.selectCableType
            );
            if( index_cable == undefined) {
              //console.log('selectCableType is not availabe for this connector.');
              this.selectCableType = '';
              this.selectCableType_Img = '';
              this.cable_impedance =  '--';
              this.cable_wkfreq =  '--';
              this.cable_material = '--';
              this.cable_color = '--';
          }
          }
        }
      }
      else{
        this.selectConnectorB_Img = '';
      }
      //console.log(`selected connectorB ${this.selectConnectorB_Img}`);
    },

    selMaterialCableChanged (value) {
      //console.log(`selected Cable ${value}`);
      //alert('select cable type ${value}');
      this.selectCableType = value;
      
      const selectedCable = ProductsDBInstance.cables.find(
          (cable) => value === cable.Name
      );
      //const selectedCable = this.productionsDB.cables.find(
      //    (cable) => value === cable.Name
      //);

      if (selectedCable){
        //this.selectCableType_Img = this.assetsBase + selectedCable.TData[6].Picture;
        //this.cable_impedance =  selectedCable.TData[0].Impedance;
        //this.cable_wkfreq =  selectedCable.TData[1].WorkFreq;
        //this.cable_material = selectedCable.TData[4].Material;
        //this.cable_color = selectedCable.TData[5].Color;
        this.selectCableType_Img = this.assetsBase + selectedCable.TData.Picture;
        this.cable_impedance =  selectedCable.TData.Impedance;
        this.cable_wkfreq =  selectedCable.TData.WorkFreq;
        this.cable_material = selectedCable.TData.Material;
        this.cable_color = selectedCable.TData.Color;
      }
      else{
        this.selectCableType_Img = '';
        this.cable_impedance =  '--';
        this.cable_wkfreq =  '--';
        this.cable_material = '--';
        this.cable_color = '--';
      }

    },

    selUnitChanged (value) {
      //console.log(`selected unit ${value}`);
      this.lengthUint = value;
    }, 


    calculate () {
      
      //check if connecto A, B and cable are selected
      if ((this.selectConnectorA === "") || (this.selectConnectorA === "-")){
        this.msg_title = "Connector";
        this.atten_msg= "Connector A is not selected !";
        this.open_msg_wnd = true;
        this.$refs.connector_a.focus();
        return;

      }
      if ((this.selectConnectorB === "")  || (this.selectConnectorB === "-")){
        this.msg_title = "Connector";
        this.atten_msg= "Connector B is not selected !";
        this.open_msg_wnd = true;
        this.$refs.connector_b.focus();
        return;

      }
      if ((this.selectCableType === "")  || (this.selectCableType === "-")){
        this.msg_title = "Cable";
        this.atten_msg= "Cable is not selected !";
        this.open_msg_wnd = true;
        this.$refs.cable.focus();
        return;

      }

      if (this.amount === 0){
        this.msg_title = "Quantity ";
        this.atten_msg= "Please provide the order quantity !";
        this.open_msg_wnd = true;
        this.$refs.cable_length.focus();
        return;

      }

      if (this.cableLength === 0){
        this.msg_title = "Cable Length ";
        this.atten_msg= "Please provide cable length !";
        this.open_msg_wnd = true;
        this.$refs.quantity.focus();
        return;

      }

      //this.price = this.productions.CalculatePrice(`${this.selectConnectorA}`, `${this.selectConnectorB}`, `${this.selectCableType}`,
      //                                            `${this.cableLength}`,`${this.amount}`);
      
      //this.time =   this.productions.LeadTimeInDay(`${this.selectConnectorA}`, `${this.selectConnectorB}`, `${this.selectCableType}`,
      //                                                  `${this.cableLength}`, `${this.amount}`);
      
      this.weight = ProductsDBInstance.WeightInKG(`${this.selectConnectorA}`, `${this.selectConnectorB}`, `${this.selectCableType}`,
                                                   `${this.cableLength}`, `${this.amount}`);
      const self = this;
      ProductsDBInstance.performTask(1, `${this.selectConnectorA}`, `${this.selectConnectorB}`, `${this.selectCableType}`,
                                                        `${this.cableLength}`,`${this.amount}`)
                                                         .then(function(result){
                                                            //console.log("Price return: " + result);
                                                            var data = JSON.parse(result);
                                                            self.price = data.values[0].val;
                                                            //console.log("new calculated price from db:" + data.values[0].val);
                            
                                                          })
                                                         .catch(function (error){
                                                            console.log("Error cal. price:" + error);
                                                            self.price = 0;
                                                        });

     ProductsDBInstance.performTask(9, `${this.selectConnectorA}`, `${this.selectConnectorB}`, `${this.selectCableType}`,
                                                   `${this.cableLength}`,`${this.amount}`).then(function(result){
                                                            //console.log("Price return: " + result);
                                                            var data = JSON.parse(result);
                                                            self.time = data.values[0].val;
                                                            //console.log("new calculated leadtime from db:" + data.values[0].val);
                                                         })
                                                         .catch(function (error){
                                                            console.log("Error cal. time:" + error);
                                                            self.time = 0;
                                                        });

    
      
      this.input_disable = true;
    },
    
    addToCart() {
     
     if (this.price === 0.0){
       this.msg_title="Invalid product";
       this.atten_msg= "No valid products and product prices!";
       this.open_msg_wnd = true;

       return;
     }
    
     //determin if product is standard or manual made
     var a_m = ProductsDBInstance.CheckIfStandProduct(`${this.selectConnectorA}`, `${this.selectConnectorB}`, 
                                                    `${this.selectCableType}`,  `${this.cableLength}`);
     //add new product
     const new_product = {
      
       C_A:`${this.selectConnectorA}`,
       C_B:`${this.selectConnectorB}`,
       CAB:`${this.selectCableType}`,
       C_L: `${this.cableLength}`,
     //add new product
      
      Product: this.selectConnectorA + '<--' +
               this.selectCableType + "(" + this.cableLength + "cm )" + '-->' + this.selectConnectorB,
      
      Quantity: this.amount,
      Sub_Totl: this.price,
      Weight: this.weight,
      Lead_Time: this.time,
      If_a_m: a_m,
    };
     
     //in case of duplicated products, the function return true
     if (  !this.$refs.ShoppingCardCheckout.addProductInShoppingCart(new_product) /*!this.$refs.ShoppingCartTable.addProductInShoppingCart(new_product)*/) {
       this.resetInput();
     }
  
   },

    handleReEditUserInput(){
      this.input_disable = false;

      //reset inqury results
      this.price = 0.00;
      this.time = 0;
      this.weight= 0.0000;
    },


    handleShoppingChangedEvent(){
      this.payment_visable = false;
      this.addprd_disabled = false;
      this.$refs.ShoppingCartTable.ToggleShippingSelection(false); 
    },

    handlePaymentEvent(whattodo){
      
      ////console.log( whattodo+ "..............." + whattodo);
      if (whattodo.length == 13 && (whattodo.indexOf("-") !== -1)){
        this.msg_title = "Payment successful";
        this.atten_msg= "Thank you for your Order ! \n\n" +
                         "Order-ID: " + whattodo + "\n\n" + 
                         "(If you haven't received the order confirmation email in a few minutes, \n" +
                         "please send us an email at contact@h-2technik.com and include this order number.)";
        this.open_msg_wnd = true;
        
        //2023.10.28
        //update the inventory on server side
        ProductsDBInstance.updateInventory(this.$refs.ShoppingCartTable.getItems());
        
        //send mail to customer and BCC to contact@h-2technik
        this.sendMail(this.$refs.PaypalBlock.pay_shippingaddress[0].email + "/" + "contact@h-2technik.com",
                      "Your Order in detail by h-2technik" + " (Order-ID:" + whattodo + ")", 
                      "Dear Mrs./Miss/Mr. " + this.$refs.PaypalBlock.pay_shippingaddress[0].name1+" " +
                                             this.$refs.PaypalBlock.pay_shippingaddress[0].name2+" " + 
                                             this.$refs.PaypalBlock.pay_shippingaddress[0].name3 + "\n\n Thank you for your order. The details of your order are as follows: \n\n" +
                                             "******************************************** \n" + 
                                             this.$refs.ShoppingCartTable.getAllProductsInStr() + 
                                             "\n Your shipping address is as follows: [\n"+
                                             this.$refs.PaypalBlock.pay_shippingaddress[0].street + " " +this.$refs.PaypalBlock.pay_shippingaddress[0].housenr + "\n" +
                                             this.$refs.PaypalBlock.pay_shippingaddress[0].postcode + " " +this.$refs.PaypalBlock.pay_shippingaddress[0].region + "\n" +
                                             this.$refs.PaypalBlock.pay_shippingaddress[0].country+ "\n" + 
                                             "Tel: " + this.$refs.PaypalBlock.pay_shippingaddress[0].phone+ "\n]\n" +
                                             EmailSignature());

         //reset shoppingcart table
        this.$refs.ShoppingCartTable.resetTable();
        this.payment_visable = false;
        this.addprd_disabled = false;
        this.disable_chk_user_agree = false;
        this.user_agree_cond = false;
      }
      //Erro payment is broken 
      else if (whattodo === 2){
        this.msg_title = "Error";
        this.atten_msg= "An error occurred during the payment process, and the payment has been terminated.";
        this.open_msg_wnd = true;
      }
      //shipping address error
      else if (whattodo.length >=5){
        this.msg_title = "Invalide Shipping Address !";
        this.atten_msg= whattodo.substring(1);
        this.open_msg_wnd = true;
      }

      ////console.log( "++++++++++" + whattodo + "..............." + this.$refs.PaypalBlock.pay_shippingaddress[0].name1);
      
    },


    closeMessageWnd(){
      this.open_msg_wnd = false;
    },

    resetInput() {
      //this.$refs.ShoppingCartTable.ToggleShippingSelection(false); 
      this.$refs.ShoppingCardCheckout.ToggleShippingSelection(false); 
      this.input_disable = false;
      this.addprd_disabled = false;
      this.selectConnectorA = '';
      this.selectConnectorB = '';
      this.selectCableType = '';
      this.cableLength = 0;
      this.lengthUint = '';
      this.amount = 0;
      this.price = 0.00;
      this.time = 0;
      this.weight= 0.0000;
      this.connector_a_impedance = '';
      this.connector_a_material = '';
      this.connector_a_wkfreq='';
      this.connector_b_impedance = '';
      this.connector_b_material = '';
      this.connector_b_wkfreq='';
      this.selectConnectorA_Img= '';
      this.selectConnectorB_Img= '';
      this.selectCableType_Img= '';
      this.cable_impedance= '';
      this.cable_wkfreq= '';
      this.cable_material= '';
      this.cable_color= '';

      this.selectConnectorA_Img = './assets/connector/no.png';
      this.selectConnectorB_Img = './assets/connector/no.png';
      this.selectCableType_Img =  './assets/connector/no.png';
    },

   
     
    sendMail(mails, subject, contents){
      //console.log( mails );
      const data = {
        adds: mails, 
        subj: subject, 
        body: contents,
      };
      
      SendAjaxRequest(
        data,
        () => {
          // Erfolgsfall
          this.uemail = "";
          this.uphone = "";
          this.usubject = "";
          this.uvalue = "";
          //console.log("O.K. sending email");
        },
        () => {
          // Fehlerfall
          //console.log("error sending email");
        }
      );
    },

    handleopenGTCorRW(whattoload){

      if (whattoload === 1){
        this.draw_title ="General Terms and Conditions";
        this.currentComponent = GeneralTermsConditions;
      }
      if (whattoload === 2){
        this.draw_title ="Righ of Widthdrawal";
        this.currentComponent = RightWithdraw;
      }
      this.open_GTC = true;
    },

    scrollToShoppingCart() {
      
      // Rufen Sie das DOM-Element mit der ID "showpoint" ab
       const showpointElement = document.getElementById("ShoppingCardID");
      if (showpointElement) {
        // Scrollen Sie zur Position des Elements
        showpointElement.scrollIntoView({ behavior: "smooth" });
        //console.log("i am here !");
      }
    },

    scrollToPayShowpoint() {
       // Rufen Sie das DOM-Element mit der ID "showpoint" ab
       const showpointElement = document.getElementById("PayBlockPoint");
      if (showpointElement) {
        // Scrollen Sie zur Position des Elements
        showpointElement.scrollIntoView({ behavior: "smooth" });
        //console.log("scrolled to payblock");
      }
    },
  },
}
</script>



<style scoped >

/*import {DownOutlined} from '@ant-design/icons-vue';
import logoImage from './china.jpg'; */

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.space-align-block {
  margin: 3px 3px;
  border: 1px solid #7cb305;
  border-radius: 8px;
  padding: 4px;
  flex: none;
  height: 300px;
  overflow: auto; 
  width: 99%;
}
.space-align-block .mock-block {
  display: inline-block;
  text-align: left;
  padding: 2px 2px 2px 2px;
  margin-right: 5px;
  border-right: 1px solid #0000ff;
  color: gray
  /*background: rgba(150, 150, 150, 0.2);*/
}

.breadcrumb-container{
  display:flex;
  justify-content:center;
  margin-top: 10px;
}


</style>
