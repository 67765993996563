<template>
  <div class="space-align-container">
    <div class="space-align-block">
      <a-card title="Please leave your message" :bordered="true" style="width: 100%">
        <a-input v-model:value="uemail" @input="clearResult" addon-before="E-Mail" />
        <br /><br />
   
        <a-input v-model:value="uphone" @input="clearResult" addon-before="Phone" />
        <br /><br />
 
        <a-input v-model:value="usubject" @input="clearResult" addon-before="Subject" />
        <br /><br />

        <a-textarea v-model:value="uvalue" @input="clearResult" placeholder="Your Message" :rows="4" />
        <br /><br />
        <p>{{ result_msg }}</p>
        <a-space align="center">
          <a-input-group compact>
            <a-input v-model:value="captchaCode" style="width: 30%; background-color: gray;" />
            <a-input v-model:value="userInput" placeholder="inpput capacha code" style="width: 50%;" />
          </a-input-group>
          <a-button type="primary" style="margin: 8px" @click="sendEmail">Send Message</a-button>
        </a-space>
       
      </a-card>
    </div>

    <div class="space-align-block">
      <a-card title="You find us" :bordered="true" style="width: 100%">
        <a-card-meta title="">
          <template #description>
            <p style="text-align:left; margin: 10px">H-2 Technik UG, a dynamic and vibrant small company with a big heart. 
            We may be small in size, but we are bursting with energy, enthusiasm, and a can-do spirit. 
            Our commitment to excellence and dedication to our customers set us apart in the industry.
            We pride ourselves on our responsiveness. We understand that your time is valuable, 
            and we make it a priority to provide prompt and efficient responses to your inquiries.
            Our team is always ready to assist you, ensuring that your needs are met without delay.
            </p>
          </template>
       </a-card-meta>

        <a-card-grid style="width: 50%; text-align: center">
          <a-image :wdith="1000" src="./assets/others/karlsruhe_Bild.jpg" :preview="false"/>
        </a-card-grid>
        <a-card-grid style="width: 50%; text-align: left">
            <p>Donauschwabenstr. 13,</p>
            <p>76149 Karlsruhe, Germany</p>
            <p>Tel: +49 721 6624655</p>
            <p>Mail: contact@h-2technik.com</p>
         </a-card-grid>
     
      
      </a-card>
    </div>

  </div>
</template>


<script >
import { validateEmail, validatePhone, validateEmptyInput } from "@/DataModel/JsFunctions";
import $ from 'jquery';

export default {
  name: 'ContactUS',
  data() {
    
      return{

        uemail:'',
        usubject:'',
        uphone:'',
        uvalue:'',
        result_msg:"",

        captchaCode: this.generateCaptcha(), // Zufällig generierter Captcha-Code
        userInput: '', // Benutzereingabe

      }
      
  },
  methods: {
    generateCaptcha() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"§$%&/()=?#ÜÖÄüöä"';
      const captchaLength = 6; // Die Länge deines Captcha-Codes

      let captchaCode = '';

      for (let i = 0; i < captchaLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        captchaCode += characters[randomIndex];
       }
        return captchaCode;
    },


    sendEmail() {

      if (!validateEmail(this.uemail)) {
        this.result_msg = "Invalid email format"; // 设置错误信息
        return;
      }

      if (!validatePhone(this.uphone)) {
        this.result_msg = "Invalid phone number format"; // 设置错误信息
        return;
      }
      
      if (!validateEmptyInput(this.usubject)){
        this.result_msg = "Subject must be not empty"; // 设置错误信息
        return;
      }

      if (!validateEmptyInput(this.uvalue)){
        this.result_msg = "Your message must be not empty"; // 设置错误信息
        return;
      }
      
      const uemail = this.uemail;
      const uphone = this.uphone;
      const usubject = this.usubject;
      const uvalue = this.uvalue;

      const vm = this; // 

      if (this.userInput === this.captchaCode) {
      //if (this.userInput === "") {
        $.ajax({
          type: "POST",
          url: "https://h-2technik.com/exe/mailsender.php",
          data: { adds: "contact@h-2technik.com", //send ourself contact@h-2technik.com, which is hard-coded in php
                  subj: usubject + "----[Mail:" + uemail + " | Phone: " + uphone + "]",
                  body: uvalue}
          })
          .done(function( ) {
            //console.log("sending email ok");
            vm.result_msg = "Thank you for your message. We will get contact you as soon as possible.";
            vm.uemail="";
            vm.uphone="";
            vm.usubject=""; 
            vm.uvalue="";
        });
        } else {
          this.result_msg = "Wrong capacha code !";  
        }
     },
    
     /*validateEmail() {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(this.uemail);
    },
    
     validatePhone() {
       const phoneRegex = /^(\+\d{9,13}|\d{9,13})$/;
       return phoneRegex.test(this.uphone);
     },
     
      validateEmptyInput(val) {
      const subjectRegex = /\S+/;
     return subjectRegex.test(val);
    },
     */

     clearResult(){
      this.result_msg = ""; // 设置错误信息
     },
    

  },
};
</script>



<style>
  .space-align-container {
  display: flex;
  justify-content: center;
  align-items:first baseline;
  flex-wrap: wrap;
  
}
.space-align-block {
  margin: 8px 4px;
  border: 1px solid #ffffff;
  padding: 4px;
  flex: none;
  width:60%;
}

</style>

