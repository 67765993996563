<template>
    <a-layout class="layout" >
    
    <a-layout-content style="padding: 0 20px">
     
    <div class="space-align-container">
      <a-card style="margin: 10px;" hoverable >
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Step 1</label>
        <a-input-group compact>
          
          <a-input :value="ConnA_label"   style="width: 20%; " size="large"/>
          <a-select v-model:value="selectConnectorA" style="width:180px;" size="large" @change="searchIfValidAdapter(1)" :options="selectConnABTypeOptions" :disabled="input_disable"  ref="connector_a" required>
           <!--a-select-option v-for="option in selectConnABOptions" :key="option.value" :value="option.value">
              {{ option.label }}
           </a-select-option-->
          </a-select>
                   
          <a-select v-model:value="selectConnectorB" style="width:180px" size="large" @change="searchIfValidAdapter(2)" :options="selectConvert2TypeOptions" :disabled="input_disable"  ref="connector_b" required>
              <!--a-select-option v-for="option in selectConnABOptions" :key="option.value" :value="option.value">
                 {{ option.label }}
              </a-select-option-->
            </a-select>
            <a-input :value="ConnB_label"  style="width: 20%" size="large"/>
          </a-input-group>

        <a-space-compact block>
          <span class="mock-block">
            <b>Tech. Data:</b> <br>
            Impedance : <label style="font-color:red; font-weight: bold;">{{connector_a_impedance}}</label>Ω<br>
            Material: {{connector_a_material}}<br>
            Range of Freq: {{connector_a_wkfreq}}<br>
          </span>
          <a-image alt=" " :src="selectConnectorA_Img" style="height:100px"/>

        </a-space-compact>
        
       </a-space>
      
      </div>
    </a-card>

     

      <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center"  size="large">
          <label style="font-weight: bold;">Step 2</label>
         <a-input-number v-model:value="amount" addonBefore="Quantity" size="large" min="1" max="10" style="width:67%" :disabled="input_disable"  ref="quantity"></a-input-number>
         <a-space size="large">
          <a-button type="primary" style="margin: 8px" @click='calculate' :disabled="addprd_disabled">Inqury</a-button>
          <a-button type="primary" @click="resetInput" :disabled="addprd_disabled">Reset</a-button>
          </a-space>
        </a-space>
      </div>
    </a-card>

  <a-card style="margin: 10px" hoverable>
      <div class="space-align-block">
        <a-space direction="vertical" align="center" size="large">
          <label style="font-weight: bold;">Inqury Results</label>
         <a-input-group >
          <a-input-number :value="price" addonBefore="Price" size="large" addon-after="€ (inkl. 19% V.A.T)" style="width:75%" disabled></a-input-number>
          <br />
          <a-input-number v-model:value="weight" addonBefore="Weight" size="large" addon-after="Kg" style="width:75%" disabled></a-input-number>
          <br />
          <a-input-number v-model:value="time" addonBefore="Lead Time" size="large" addon-after="Day" style="width:75%" disabled></a-input-number>
    
         </a-input-group>

         <a-space size="large">
          <a-button type="primary" @click="addToCart" :disabled="addprd_disabled">Add to Cart</a-button>
          
        </a-space>


       </a-space>
      </div>
  </a-card>

  <a-card style="margin: 10px" hoverable>       
      <div>
        <a-space direction="vertical" align="center" size="large">
          <ShoppingCardCheckout  ref='ShoppingCardCheckout' @openGTCorRWDEvent="handleopenGTCorRW" @resetUserInput="resetInput"/>
         </a-space>
       </div>
   </a-card>
</div>
    
   
    </a-layout-content>
    </a-layout>
    
    <!-- Page assistant information block  -->
    <a-modal v-model:open="open_msg_wnd" :title="msg_title" @ok="closeMessageWnd">
      <span style="white-space: pre-line;">{{ atten_msg }}</span>
      <!--p>{{ atten_msg }}</p-->
     
    </a-modal>

    <a-drawer
       v-model:open="open_GTC"
       class="custom-class"
       root-class-name="root-class-name"
       :root-style="{ color: 'blue' }"
       style="color: black"
       :title="draw_title"
      placement="right"
      @close="scrollToShoppingCart"
     >
      <component :is="currentComponent" />
     </a-drawer>

</template>


<script >
//import {ShopOutlined} from '@ant-design/icons-vue';
//import ShoppingCartTable from './TestTable.vue';
import ShoppingCardCheckout from './Checkout2.vue';
import GeneralTermsConditions from './GeneralTermsConditions.vue';
import RightWithdraw from './RightWithdraw.vue';
import { ref } from 'vue';
import { /*AdapterDB,*/ ProductsDBInstance } from "@/DataModel/ProductDBDataStruct";
import { SendAjaxRequest, /*EmailSignature*/ } from "@/DataModel/JsFunctions";
import { markRaw } from 'vue';
//import {CartItem} from '../DataModel/CartItemDB'



export default {
  name: 'ConnectorAssemble',
  props: {
    msg: String,
    id: {
      type: String,
      required: false
    }
  },
  components: {
    //ShoppingCartTable, // shopping cart table
    GeneralTermsConditions:markRaw(GeneralTermsConditions), //GTC template
    RightWithdraw:markRaw(RightWithdraw),      //right of withdraw  
    ShoppingCardCheckout: markRaw(ShoppingCardCheckout),
  },

  data() {
    return {
      
      local_available: ref(false),
      CartItem:null,   //cart item template
      //productions: new Productions(),
      //productionsDB: new ProductionsDB(),
      //shippings: new ShippingData(),
      selectConnectorA: '',
      selectConnectorB: '',
      //selectCableType: '',

      CConnertorA: ref(null),
      connector_a_impedance: '',
      connector_a_material: '',
      connector_a_wkfreq:'',
      //connector_b_impedance: '',
      //connector_b_material: '',
      //connector_b_wkfreq:'',

      //cable_impedance:'',
      //cable_wkfreq:'',
      //cable_material:'',
      //cable_color:'',

      assetsBase: './',
      ConnA_label: 'Connector A',
      //CableType_label: 'Coaxial Type',
      ConnB_label: 'Connector B',
      
      
      selectConnectorA_Img: './assets/connector/no.png',
      //selectConnectorB_Img: './assets/connector/no.png',
      //selectCableType_Img:  './assets/connector/no.png',
      price: 0.0,
      cableLength: 0,
      amount: 0,
      time: 0,
      weight: 0,
      temp_weight:'',

      user_agree_cond: ref(false),    //boolean, general termins and condition & right of withdraw
      ShoppingCartTable: ref(null),  //child component
      ShoppingCardCheckout:ref(null), //12.05.2024
      //PaypalBlock: ref(null),       //child component 
      
      input_disable: ref(false),
      addprd_disabled:ref(false),
    
      msg_title:'',
      open_msg_wnd:ref(false),
      open_GTC: ref(false),
      draw_title:"",
      disable_chk_user_agree:ref(false),
      atten_msg:``,
    

      selectConnABOptions: [],
      selectConnABTypeOptions: [],   //not used 25.07.2024, due to the new concept of rawconnectortype table (matrix)
      selectConvert2TypeOptions: [],
      //selectCableOptions: [],
      selectUnitOptions: [
        { value: 'cm', label: 'cm'},
        { value: 'm', label: 'm' }
      ],

      debug: 0,
    };
  },

  /*beforecreate(){
   
  },*/

  mounted() { 
    
    //*********************************************************18.01.2024 copy connector info from ProductsDBInstance to local selectConnABOptions
    for(var i = 0; i < ProductsDBInstance.rawconntypes.length; i++)
    {
      this.selectConnABOptions.push({value: ProductsDBInstance.rawconntypes[i].Name.trim(), 
                                     label: ProductsDBInstance.rawconntypes[i].Name.trim(), 
                                     img: ""});
    }
    //console.log(this.selectConnABOptions.length);
    this.initVlaue();
   
  },

  methods: {
  

    /***********************************************************************************************************/
    initVlaue () {
      this.sortenMaterial(this.selectConnABOptions, this.selectConnABTypeOptions);
    },
    //Sorten the productions
    sortenMaterial(options, targetoptions) {
      //console.log('sortenMaterial---');
      var types = [];
      for(var i = 0; i < options.length; i++){
        const type = options[i].value.split('-')[0];
        var newEle = true;
        for(var j = 0; j < types.length; j++){
          if(types[j] == type){
            newEle = false;
            break;
          }
        }
        if(newEle){
          var optiosTypes = [];
          for(var n=i; n < options.length; n++){
            if(options[n].value.split('-')[0] == type)
              optiosTypes.push(options[n]);
        }
          
          var ele = {label: this.evaluateTypeString(type), options: optiosTypes};
          
          //this.selectConnABTypeOptions.push(ele);
          targetoptions.push(ele);
          //console.log(ele);
          types.push(type);
        }
      }

      //this.selectConnABTypeOptions.sort((a, b) => {
        targetoptions.sort((a, b) => {  
        const labelA = a.label.toUpperCase(); // Für Groß-/Kleinschreibung ignorieren
        const labelB = b.label.toUpperCase(); // Für Groß-/Kleinschreibung ignorieren
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
     }); 

    },

    evaluateTypeString(typeStr) {
     
      if (typeStr  === 'empty') {
          return "No connector";   
      }

      if (typeStr.charAt(0) === 'R') {
          return typeStr.slice(1) + "(Reversed)";
          
      } else {
          return typeStr;
      }
    },

    
    searchIfValidAdapter(whattodo){
      
      if (this.selectConnectorA.length == 0 ) {
          return;
      }
      
      //25.07.2024, load the converted matrix(rawconnectortype) and return
      if (whattodo == 1){
        this.selectConnectorB='';
        this.connector_a_impedance = '';
        this.connector_a_material = '';
        this.connector_a_wkfreq='';
        this.selectConnectorA_Img= '';
        this.selectConvert2TypeOptions = [];
    
        const tmp_adapter = ProductsDBInstance.rawconntypes.find(adapter => adapter.Name === this.selectConnectorA);
        if (tmp_adapter) { // 确保 tmp_adapter 不是 undefined
            const tmp_options = tmp_adapter.convert2.split(",").map(option => ({ value: option.trim() }));
            this.sortenMaterial(tmp_options,this.selectConvert2TypeOptions );
            
        } else {
        // 处理没有找到适配器的情况，例如设置一个空数组或者其他默认值
            this.selectConvert2TypeOptions = [];
        }
        return;
      }


      //04.06.2024 search in database and fetch the img path, tech data 
      //console.log(this.selectConnectorA+ "<->"+this.selectConnectorB);
      //let adapter_name = "label='" + this.selectConnectorA+ "<->"+this.selectConnectorB + "'" +
      //                  " or label='" + this.selectConnectorB+ "<->"+this.selectConnectorA + "'";
      
      let adapter_name = "label='" + this.selectConnectorA+ "<->"+this.selectConnectorB + "'" +
                        " or label='" + this.selectConnectorB+ "<->"+this.selectConnectorA + "'";
      

      //console.log("dapter_name: " + adapter_name);

      ProductsDBInstance.fetchData(10, adapter_name)
                         .then((result)=>{
                            var data = JSON.parse(result);
                            //extract img path and tech data from result  
                            //console.log("Adapter data is retunred ..." + data[0].Available);
                            //if connector available

                            if (data[0].Available){
                              //console.log(data[0].tech_data.Picture);
                              this.selectConnectorA_Img = this.assetsBase + data[0].tech_data.Picture;
                              this.connector_a_impedance=data[0].tech_data.Impedance;
                              this.connector_a_material=data[0].tech_data.Material;
                              this.connector_a_wkfreq=data[0].tech_data.WorkFreq;
                              this.amount = 0;                                      //reset quantity
                              this.temp_weight = data[0].tech_data.Weight;               //the weight of acutal select adapter is set here !!!
                              this.local_available = true; 
                            }

                            //not available
                            else{
                              this.local_available = false;  //if the current combination available
                              this.selectConnectorA_Img = this.assetsBase + "assets/connector/NA.png";
                            }
                            //console.log(data[0].tech_data.WorkFreq);
                           
                          })
                          .catch(function (){
                            //console.log("error fetching img" );
                          });

    },

    calculate(){

      if(!this.local_available){
        this.msg_title = "Connector";
        this.atten_msg= "Selected connector combination is not available!";
        this.open_msg_wnd = true;
        this.$refs.connector_a.focus();
        return;
      }


         //check if connecto A, B and cable are selected
      if ((this.selectConnectorA === "") || (this.selectConnectorA === "-")){
        this.msg_title = "Connector";
        this.atten_msg= "Connector A is not selected !";
        this.open_msg_wnd = true;
        this.$refs.connector_a.focus();
        return;

      }
      if ((this.selectConnectorB === "")  || (this.selectConnectorB === "-")){
        this.msg_title = "Connector";
        this.atten_msg= "Connector B is not selected !";
        this.open_msg_wnd = true;
        this.$refs.connector_b.focus();
        return;

      }

      if (this.amount === 0){
        this.msg_title = "Quantity ";
        this.atten_msg= "Please provide the order quantity !";
        this.open_msg_wnd = true;
        return;
      }

      //call background function to calculating price, the cable type is "<->" and lenght=0
      ProductsDBInstance.performTask(1, this.selectConnectorA, this.selectConnectorB, "<->", 0, this.amount)
                                                         .then(data=>{
                                                            //console.log("Price return: " + JSON.parse(data));
                                                            var val = JSON.parse(data)
                                                            // Assuming data structure is correct
                                                            this.price = val.values[0].val;
                                                            this.weight = (this.temp_weight * this.amount).toFixed(3);
                                                          })
                                                         .catch(error=>{
                                                            console.log("Error cal. price:" + error.message || error);
                                                            this.price = 0;
                                                            this.weight = 0;
                                                        });



      ProductsDBInstance.performTask(9, this.selectConnectorA, this.selectConnectorB, "<->", 0, this.amount)
                                                         .then(data=>{
                                                            //console.log("leadtime return: " + JSON.parse(data));
                                                            var val = JSON.parse(data)
                                                            // Assuming data structure is correct
                                                            this.time = val.values[0].val;
                                                          
                                                          })
                                                         .catch(error=>{
                                                            console.log("Error cal. leadtime:" + error.message || error);
                                                            this.time = 0;
                                                        });

       this.input_disable = true;


    },

    addToCart() {

      //console.log("---weight=" +this.$store.getters.totalWeight)
     
     if (this.price === 0.0){
       this.msg_title="Invalid product";
       this.atten_msg= "No valid products and product prices!";
       this.open_msg_wnd = true;
       return;
     }
    
     //26.05.2024 following function is not necessary for connector adapter
     //determin if product is standard or manual made
     //var a_m = ProductsDBInstance.CheckIfStandProduct(`${this.selectConnectorA}`, `${this.selectConnectorB}`, 
     //                                               `${this.selectCableType}`,  `${this.cableLength}`);
     
     //add new product
     const new_product = {
      
       C_A:`${this.selectConnectorA}`,
       C_B:`${this.selectConnectorB}`,
       CAB:"<->",                         //cable type <-> for adapter
       C_L: 0,                          //calbe length = 0
     //add new product
      
      Product: this.selectConnectorA + '<->' + this.selectConnectorB,
      
      Quantity: this.amount,
      Sub_Totl: this.price,
      Weight: this.weight,
      Lead_Time: this.time,
      If_a_m: true,                   //standard product
    };
     
     //in case of duplicated products, the function return true
     if (!this.$refs.ShoppingCardCheckout.addProductInShoppingCart(new_product)) {
       this.resetInput();
     }
  
   },


    closeMessageWnd(){
      this.open_msg_wnd = false;
    },

    resetInput() {
      this.$refs.ShoppingCardCheckout.ToggleShippingSelection(false); 
      this.input_disable = false;
      this.addprd_disabled = false;
      this.selectConnectorA = '';
      this.selectConnectorB = '';
      //this.selectCableType = '';
      //this.cableLength = 0;
      //this.lengthUint = '';
      this.amount = 0;
      this.price = 0.00;
      this.time = 0;
      this.weight= 0.0000;
      this.connector_a_impedance = '';
      this.connector_a_material = '';
      this.connector_a_wkfreq='';
      //this.connector_b_impedance = '';
      //this.connector_b_material = '';
      //this.connector_b_wkfreq='';
      this.selectConnectorA_Img= '';
      //this.selectConnectorB_Img= '';
      //this.selectCableType_Img= '';
      //this.cable_impedance= '';
      //this.cable_wkfreq= '';
      //this.cable_material= '';
      //this.cable_color= '';

      //this.selectConnectorA_Img = './assets/connector/no.png';
      //this.selectConnectorB_Img = './assets/connector/no.png';
      //this.selectCableType_Img =  './assets/connector/no.png';
    },

   
     
    sendMail(mails, subject, contents){
      //console.log( mails );
      const data = {
        adds: mails, 
        subj: subject, 
        body: contents,
      };
      
      SendAjaxRequest(
        data,
        () => {
          // Erfolgsfall
          this.uemail = "";
          this.uphone = "";
          this.usubject = "";
          this.uvalue = "";
          //console.log("O.K. sending email");
        },
        () => {
          // Fehlerfall
          //console.log("error sending email");
        }
      );
    },

    handleopenGTCorRW(whattoload){

      if (whattoload === 1){
        this.draw_title ="General Terms and Conditions";
        this.currentComponent = GeneralTermsConditions;
      }
      if (whattoload === 2){
        this.draw_title ="Righ of Widthdrawal";
        this.currentComponent = RightWithdraw;
      }
      this.open_GTC = true;
    },

    scrollToShoppingCart() {
      
      // Rufen Sie das DOM-Element mit der ID "showpoint" ab
       const showpointElement = document.getElementById("ShoppingCardID");
      if (showpointElement) {
        // Scrollen Sie zur Position des Elements
        showpointElement.scrollIntoView({ behavior: "smooth" });
        //console.log("i am here !");
      }
    },

    scrollToPayShowpoint() {
       // Rufen Sie das DOM-Element mit der ID "showpoint" ab
       const showpointElement = document.getElementById("PayBlockPoint");
      if (showpointElement) {
        // Scrollen Sie zur Position des Elements
        showpointElement.scrollIntoView({ behavior: "smooth" });
        //console.log("scrolled to payblock");
      }
    },

  },
}
</script>



<style scoped >

/*import {DownOutlined} from '@ant-design/icons-vue';
import logoImage from './china.jpg'; */

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.space-align-block {
  margin: 3px 3px;
  border: 1px solid #7cb305;
  border-radius: 8px;
  padding: 4px;
  flex: none;
  height: 300px;
  overflow: auto; 
  width: 99%;
}
.space-align-block .mock-block {
  display: inline-block;
  text-align: left;
  padding: 2px 2px 2px 2px;
  margin-right: 5px;
  border-right: 1px solid #0000ff;
  color: gray
  /*background: rgba(150, 150, 150, 0.2);*/
}

.breadcrumb-container{
  display:flex;
  justify-content:center;
  margin-top: 10px;
}


</style>
