<template>
    <div class="space-align-container">
      <div class="space-align-block">
        <a-card title="Private Policy" :bordered="true" style="width: 100%">
            
<p>We take the protection of your personal data very seriously and treat your personal data confidentially and in accordance 
  with the statutory data protection regulations and this data protection declaration. With the following information we give you an overview of the processing of your personal data by us and your rights under data protection law.</p>
<p>1.	Who is responsible for data processing and who can I contact? <br />
The responsible body<br /><strong>H-2 Technik UG(haftungsbeschränkt) </strong><br /><strong>
Donauschwabenstr. 13, </strong><br /><strong>
76149 Karlsruhe </strong><br /><strong>
Telephone: 49 15901326730 </strong><br /><strong>
Internet: www.h-2technik.com </strong><br /><strong>
Email: contact@h-2technik.com</strong> <br />
</p>
<p>2.	Who does this privacy policy apply to?<br /><br />
This data protection declaration is about the visitors to our website, in particular our members, interested parties and people who use and want to use our offers, or want to find out about our products and services, as well as initial information for all people whose data we collect researched from publicly available sources or obtained through business cards and used for the purpose of the inquiry.
</p>
<p>3.	What data do we use?<br /><br />
In principle, you can visit our websites without telling us your identity, unless you send us an email or place order, register for our webshop to use our online services. We only process the data that is required to answer your request or to provide our services. Which data is collected can be seen from the respective input forms. The required data are marked as mandatory fields. If we ask you for further information, this is voluntary information. We use this information to make our offers more individual or to better tailor them to your needs. In all other cases, we only use the data that is required to establish contact.
</p>
<p>4.	For what purposes and on what legal basis do we use your data?<br /><br />
We process your personal data in accordance with the provisions of the General Data Protection Regulation (EU DS-GVO) and the Federal Data Protection Act (BDSG). Please also note our information about your right of objection according to Article 21 EU DS-GVO.

a) To fulfill contractual obligations (Art. 6 Para.1b EU DS-GVO)
The processing of personal data takes place in order to carry out our offers and to carry out pre-contractual measures that are carried out at your request.

Registration as a customer
Orders for goods and services
</p>
<p>5.	Who gets my data?<br /><br />
At H-2 Technik UG, those departments that need your data to fulfill our contractual and legal obligations or to balance interests will have access to it. In order to answer inquiries, it may be necessary to transmit them to employees. Service providers and vicarious agents used by us may also receive data for these purposes if they maintain confidentiality and our data protection instructions. A transfer to third parties takes place exclusively within the framework of the regulations of the EU DS-GVO and the BDSG.
</p>
<p>5.1 Disclosure for ordering goods and services
The personal data collected by us will be passed on to the delivery company commissioned with the delivery, insofar as this is necessary for the delivery of the goods.
</p>
<p>6.	How long will my data be stored?<br /><br />
We process and store your personal data as long as it is necessary to fulfill our contractual and legal obligations. If the data is no longer required for the fulfillment of contractual or legal obligations, they are regularly deleted, unless their - limited - further processing is necessary to fulfill commercial and tax retention periods, such as the German Commercial Code and the Tax Code. The storage and documentation periods stipulated there are six to ten years.
</p>
<p>7.	 Is there an obligation to provide data?<br /><br />
As part of our business relationship or the ordering of services, you must provide the personal data that is required for the performance of the business relationship or the provision of a service and the fulfillment of the associated contractual obligations or that we are legally obliged to collect. Without this data, we usually have to refuse the conclusion of the contract or the execution of the order, or we can no longer carry out an existing contract and may therefore have to terminate it.
</p>
<p>8.	What data is processed when using the website?<br /><br />
a) 
When you visit our website, we do not receive any usage data.<br />

b) 
We don’t use any cookies in our webseite to collect user data.
</p>


         
        </a-card>
      </div>
    </div>
  </template>
  
  
  <script >

  export default {
    name: 'PrivatePolicy',
  }
  
  </script>
  
  
  
  <style scoped>
    .space-align-container {
    display: flex;
    justify-content: center;
    align-items:first baseline;
    flex-wrap: wrap;
    
  }

  .space-align-block p{
    text-align:left;
  }

  .space-align-block {
    margin: 8px 4px;
    border: 1px solid #ffffff;
    padding: 4px;
    flex: none;
    width:60%;

  }
  
  </style>
  
  