
import $ from 'jquery';

class ConnectorDB {
	constructor(p_name, p_TData, p_suitable_cable) {
		this.Name = p_name;
		this.TData = p_TData;
		this.SuitableCable = p_suitable_cable;

	}
}

class CoaxialDB {
	constructor(p_name, p_TData) {
		
		this.Name = p_name;
		this.TData = p_TData;
	}
}

class AdapterDB{
	constructor(p_name, p_convert2) {
		this.Name = p_name;
		this.convert2 = p_convert2;
	}
}

class ProductionsDB {
	constructor() {
		this.rawconntypes = [];
		this.connectors = [];
		this.cables = [];
		this.StandardProducts=[]; 
		this.path = "https://www.h-2technik.com/pigtail50_exe/";
		//this.path = "http://192.168.178.51/pigtail50_exe/";
		
	}

	//initate local liste of connectors and cables
	//what = 1 connector; what = 2 cable
	fetchData(what, str) {
		const self = this; // Speichern Sie eine Referenz auf "this"
        const lw = what;
        let  parameters={material: lw, detail:str};
        //console.log("what ...." + what);
        
        return new Promise(function(resolve, reject) {
			$.ajax({
				type:"POST",
				url: self.path + "fetchmaterials.php",
				data: parameters,
				})
				.done(function (response) {
					resolve(response); 
				})
				.fail(function(response){
                    reject(response); 
				});
           });


		/*$.ajax({
			type:"POST",
			url: self.path + "fetchmaterials.php",
			data: {material: what},
			})
            .done(function (response) {
				resolve(response); 
			})
			.fail(function(response){
				reject(response); 
			});
			.done(function (response) {
				//console.log("O.K. ---------------" + response);
				try{
					var data = JSON.parse(response);
					
					if (lw === 1){ //connector
						for (var i = 0; i<data.length; i++){
							var label = data[i].label;
							var tech_data = data[i].tech_data;
							var suitablecable = data[i].SuitableCable;
	
							self.connectors.push(new ConnectorDB(label, tech_data, suitablecable));
						}
						//console.log(self.connectors);	
					}
					if (lw === 2){ //coaxial
						for (var i1 = 0; i1<data.length; i1++){
							var label1 = data[i1].label;
							var tech_data1 = data[i1].tech_data;
	
							self.cables.push(new CoaxialDB(label1, tech_data1));
						}
						//console.log(self.cables);
					}
					if (lw === 3){ //standard products
						for (var i2 = 0; i2<data.length; i2++){
							var label2 = data[i2].label;
							self.StandardProducts.push(label2);
						}
						//console.log(self.StandardProducts);
					}
				}catch(e){
					console.error("Error: interprete returned json data !");  	
				}
            })
           .fail(function () {
                console.error("Error: reading material parameters !");  
           });*/
    }
    
	//perform operation (not used)
	performOperate(taskid, ca, cb, ct, cl, cnt){ //ca = connector A, cb=connector B, ct=cable type, cl=length, cnt = quantity
		//const self = this; // Speichern Sie eine Referenz auf "this"
		let  parameters={
			connectA: ca,
			connectB: cb,
			cableType: ct, 
			cableLength: cl,
			quantity: cnt
		};
        let exephp = "";
        if (taskid === 1){ exephp = "calculateprice.php"; }
		if (taskid === 9){ exephp = "returnleadtime.php"; }
		if (taskid === 10){ exephp = "updateinventory.php"; }
        
		$.ajax({
			type:"POST",
			url: this.path + exephp,
			data: parameters,
			})
			.done(function (response) {
				try{
					console.log("O.K. ---------------" + response);
				}catch(e){
					//console.error("Error: interprete returned json data  + !" + taskid);  	
				}
            })
           .fail(function () {
                //console.error("Error: perform operation !" + taskid);  
           });
	}

	performTask(taskid, ca, cb, ct, cl, cnt){
		const self= this;

		//const self = this; // Speichern Sie eine Referenz auf "this"
		let  parameters={
			connectA: ca,
			connectB: cb,
			cableType: ct, 
			cableLength: cl,
			quantity: cnt
		};

        let exephp = "";

        if (taskid === 1){ exephp = "calculateprice.php"; }
		if (taskid === 9){ exephp = "returnleadtime.php"; }
		if (taskid === 10){ exephp = "updateinventory.php"; }
        

		//console.log(self.path + exephp + "..." + parameters.connectA + "--"+ parameters.connectB + "--"+parameters.cableType + "--"+parameters.cableLength + "--"+parameters.quantity + "--");       
		return new Promise(function(resolve, reject) {
			$.ajax({
				type:"POST",
				url: self.path + exephp,
				data: parameters,
				})
				.done(function (response) {
					resolve(response); 
				})
				.fail(function(response){
                    reject(response); 
				});
           });
	}

	WeightInKG(conn_a, conn_b, cable, lng, cnt){
		// cable
		const cableC = this.cables.find((cble) => cable === cble.Name);
        //console.log(cableC.Name);

		//connector A, B 
		const connA = this.connectors.find((ca) => conn_a === ca.Name);
		const connB = this.connectors.find((cb) => conn_b === cb.Name);

		//weight of connectors
		let w_connectors = (connA.TData.Weight + connB.TData.Weight) * cnt;
		//console.log("weight connector = " + w_connectors);
		//weight of cable
        let w_cable = (cableC.TData.Weight/100) * lng * cnt;
		//console.log("weight cable = " + w_cable);

		return parseFloat(w_connectors+w_cable).toFixed(3)
	}


	//check if product is standard(machine made) products
	CheckIfStandProduct(conn_a, conn_b, cable_type, lng){

		//standard prodcts are only with RF0.81 or RF1.13 cable, all other cables are non-standard products
		if (cable_type.indexOf("RF0.81")===-1 && cable_type.indexOf("RF1.13")===-1 ) return false;
 
		let prokey1 = "H2_" + this.extractConnector(conn_a) + "_" + this.extractConnector(conn_b) + "_" + lng;
		let prokey2 = "H2_" + this.extractConnector(conn_b) + "_" + this.extractConnector(conn_a) + "_" + lng;
		//console.log(prokey);
		return this.StandardProducts.includes(prokey1) || this.StandardProducts.includes(prokey2);
	}
	extractConnector(str) {
		if (str.includes('SMA-K-1.5')) {return 'SMA';} 
		if (str.includes('RSMA-K-1.5')) {return 'RSMA';} 
		else if (str.includes('IPEX4')) { return 'IPEX4'; } 
		else if (str.includes('U.FL')) { return 'IPX';	} 
		else if (str.includes('N-')) {  return 'N';	}
		else if (str.includes('RF0.81')) {  return 'RF0.81';} 
		else if (str.includes('RF1.13')) {  return 'RF1.13';} 
		else if (str.includes('RF1.37')) {  return 'RF1.37';}
		else if (str.includes('RG316')) {return 'RG316'; } 
		else { return '-';}
    }

	updateInventory(products){
		products.forEach((item) => {
			
			//console.log(item.C_A + "/"+item.C_B + "/"+item.CAB + "/"+item.C_L + "/"+item.Quantity + "/");
			this.performTask(10, item.conn_A, item.conn_B, item.cable_type, item.cable_length, item.prod_quan)
                            .then(function (){
								console.log("...database updated");
							})
							.catch(function (){
								console.log("...Error on update inventory: ");
							});
        });
	}

	//prodcution time(per day 10 pcs of manual made products)
	HandProductionDay(cnt){
     
		let base_leadtime = 0;
		base_leadtime += (cnt/10);  //10 unit per day for hand-made products
		return  parseInt(base_leadtime.toFixed(0)) +  parseInt(this.AdjustLeadTimeByHour());
   }
   AdjustLeadTimeByHour(){
	const jetzt = new Date();
	const stunden = jetzt.getHours();

	if (stunden >= 11) {
     return 1; // Wenn es nach 12:00 Uhr ist
   } else {
     return 0; // Wenn es vor 12:00 Uhr ist
   }
}

}  

const ProductsDBInstance = new ProductionsDB();
export { ConnectorDB, CoaxialDB, AdapterDB, ProductionsDB, ProductsDBInstance};

